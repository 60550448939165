<template>
  <div class="container">
    <div class="title">
      <div class="row">
        <h3>{{ componentName }}</h3>
        <!-- <searchInput></searchInput> -->

        <button
          class="button"
          @click="showCreatQuation()"
          v-if="buttonName === 'Question'"
        >
          <img src="@/assets/images/icons/add.svg" alt="" srcset="" />
          Question
        </button>
        <button
          class="button"
          @click="showReviewQuation()"
          v-if="buttonName === 'Review'"
        >
          <img src="@/assets/images/icons/add.svg" alt="" srcset="" />
          Review
        </button>
      </div>
    </div>
  </div>

  <!-- Start form to create new question -->
  <pop-up-form
    button-name="Submit"
    title-popup="Add a question"
    :showPopup="showPopupCreateQuation"
    :loading-button="loadingButtonQuestion"
    @submit-form="setNewQuestion()"
    @cansel-popup="cancelPopUpFrom"
  >
    <template #body>
      <div class="input customer_email">
        <label for="">Product ID</label>
        <div class="serch">
          <div class="img-icone">
            <img src="@/assets/images/icons/search.svg" alt="" srcset="" />
          </div>
          <InputText type="text" v-model="productIdCreate" hidden />

          <button-select
            :itemsSelects="itemsSelected"
            @open-select-productPopUp="openSelectProduct"
          ></button-select>
        </div>
      </div>

      <div class="input">
        <label for="">Question</label>
        <Textarea
          type="text"
          style="min-height: 150px"
          v-model="QuestionBody"
        />
      </div>
    </template>
  </pop-up-form>
  <!-- Start Search to add new question -->
  <PopUpSelectProduct
    :show-popups-search="showChoseProductToCreateQuestion"
    @cancel-select-product="cancelSelect"
    @select-products-submit="selectProductsSubmit"
  ></PopUpSelectProduct>

  <add-new-review
    :showPopups="showPopupsAddNewReview"
    @cancel-add-review="showPopupsAddNewReview = false"
  ></add-new-review>
  <AlertComponent :messages="SMessages.messages"></AlertComponent>

  <!-- Start to create review for product -->
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import AddNewReview from "@/views/Reviews/Components/AddNewReview.vue";
import { DashboardQuestions } from "@/service/QuestionStore/DashboardQuestions.js";
import ButtonSelect from "@/components/UI/ButtonSelect.vue";
import { useTableStore } from "@/service/QuestionStore/QuationAndAnswerTable";
import PopUpSelectProduct from "@/components/UI/Popups/PopUpSelectProduct.vue";

import { storeMessages } from "@/service/storeMessages";
import AlertComponent from "@/components/UI/AlertComponent.vue";

export default {
  name: "HeaderPlugin",
  props: {
    componentName: String,
    buttonName: Boolean && String,
  },
  components: {
    PopUpForm,
    InputText,
    Textarea,
    AddNewReview,
    AlertComponent,
    PopUpSelectProduct,
    ButtonSelect,
  },
  data() {
    return {
      showPopupCreateQuation: false,
      showPopupsAddNewReview: false,
      storeDashboardQuestions: DashboardQuestions(),
      SMessages: storeMessages(),
      productIdCreate: "",
      QuestionBody: "",
      loadingButtonQuestion: false,
      store: useTableStore(),
      showChoseProductToCreateQuestion: false,
      itemsSelected: [],
    };
  },
  methods: {
    // method to show popup select product
    selectProductsSubmit(data) {
      this.itemsSelected = data;
      this.productIdCreate = data[0].product_id;
      this.cancelSelect(false);
    },
    openSelectProduct() {
      this.showChoseProductToCreateQuestion = true;
    },
    cancelSelect(value) {
      this.showChoseProductToCreateQuestion = value;
    },

    showCreatQuation() {
      this.showPopupCreateQuation = true;
    },

    showReviewQuation() {
      this.showPopupsAddNewReview = true;
    },

    cancelPopUpFrom() {
      this.showPopupCreateQuation = false;
    },

    setNewQuestion() {
      let objectCreateReview = {
        content: this.QuestionBody,
        email: "admin@lexmodo.com",
        product_id: this.productIdCreate,
        name: "Admin",
      };
      this.loadingButtonQuestion = true;
      this.storeDashboardQuestions
        .setNewQuestion(objectCreateReview)
        .then((data) => {
          this.loadingButtonQuestion = false;

          if (data === "success") {
            this.showPopupCreateQuation = false;
            this.productIdCreate = "";
            this.QuestionBody = "";
            this.store.requestGet();
            this.itemsSelected = [];
          }
        });
    },
  },
};
</script>

<style lang="scss">
// start title for plugins
.title {
  font-size: 20px;
  font-weight: 600;
  color: #202223;
  padding: 20px 0;

  button {
    align-items: center;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
