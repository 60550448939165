<template>
  <div class="empty-section-big">
    <div class="icone">
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.906 33.5791H28.125C24.2414 33.5791 21.0938 36.7268 21.0938 40.6104V54.6729H128.906V33.5791Z"
          fill="#E0EAFA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.906 29.625C128.906 25.7414 125.759 22.5938 121.875 22.5938C111.963 22.5938 94.0945 22.5938 85.1273 22.5938C81.7641 22.5938 78.6562 24.3961 76.9875 27.3164C74.2195 32.1633 70.3125 39 70.3125 39H128.906V29.625Z"
          fill="#E0EAFA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0938 40.167H128.906V44.5334H21.0938V40.167Z"
          fill="#CECECE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M136.798 53.6977C137.171 51.0117 136.362 48.2953 134.584 46.2516C132.802 44.2055 130.224 43.0312 127.512 43.0312C103.616 43.0312 46.3812 43.0312 22.4843 43.0312C19.7726 43.0312 17.1945 44.2055 15.4132 46.2516C13.6343 48.2953 12.8257 51.0117 13.1984 53.6977C15.575 70.807 20.1875 104.013 22.3132 119.323C22.9578 123.956 26.9187 127.406 31.5992 127.406H118.398C123.078 127.406 127.039 123.956 127.684 119.323C129.809 104.013 134.422 70.807 136.798 53.6977Z"
          fill="#E0EAFA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9859 59.3695C14.1617 57.4172 14.9445 55.5516 16.2546 54.0469C18.0335 52.0031 20.6117 50.8289 23.3234 50.8289H126.673C129.385 50.8289 131.963 52.0031 133.742 54.0469C135.052 55.5516 135.835 57.4172 136.011 59.3695L136.798 53.6977C137.171 51.0117 136.362 48.2953 134.584 46.2516C132.802 44.2055 130.224 43.0312 127.512 43.0312C103.616 43.0312 46.3812 43.0312 22.4843 43.0312C19.7726 43.0312 17.1945 44.2055 15.4132 46.2516C13.6343 48.2953 12.8257 51.0117 13.1984 53.6977L13.9859 59.3695Z"
          fill="#9FD5FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M64.4531 104.03C64.4531 101.442 62.3555 99.3423 59.7656 99.3423C54.5648 99.3423 46.2187 99.3423 41.0156 99.3423C38.4281 99.3423 36.3281 101.442 36.3281 104.03V108.717C36.3281 111.307 38.4281 113.405 41.0156 113.405H59.7656C62.3555 113.405 64.4531 111.307 64.4531 108.717C64.4531 107.21 64.4531 105.539 64.4531 104.03Z"
          fill="#9FD5FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.407 75C100.352 75 93 82.3518 93 91.4069C93 100.462 100.352 107.814 109.407 107.814C118.462 107.814 125.814 100.462 125.814 91.4069C125.814 82.3518 118.462 75 109.407 75ZM109.407 79.6877C115.874 79.6877 121.126 84.9395 121.126 91.4069C121.126 97.8743 115.874 103.126 109.407 103.126C102.939 103.126 97.6877 97.8743 97.6877 91.4069C97.6877 84.9395 102.939 79.6877 109.407 79.6877Z"
          fill="#1575BF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M117.951 102.738L132.527 117.313C133.442 118.229 134.927 118.229 135.843 117.313C136.757 116.399 136.757 114.913 135.843 113.999L121.267 99.4234C120.351 98.5093 118.867 98.5093 117.951 99.4234C117.037 100.337 117.037 101.823 117.951 102.738Z"
          fill="#1575BF"
        />
      </svg>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyBigTable",
  props: ["message"],
};
</script>

<style lang="scss">
.empty-section-big {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  flex-direction: column;
  gap: 0px;
  p {
    color: #202223;
    font-size: 20px;
    font-weight: 400;
  }
}
</style>
