import { defineStore } from 'pinia';
import axios from 'axios';


export const useTableStore = defineStore({
    id: 'tableQuestions',
    state: () => ({
      Api: "https://reviews-api.numinix.com/api/admin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
      },
      items:[],
      loadingTable:false,
      total: 0,
      rows:10,
      infoObject: {
       
      },
      sortField: ""
    }),



    getters: {
        getAllItemsQuation() {
            return this.items
        }
    },
    

    actions: {
      onPage(object){
        if(object){
          if(Object.prototype.hasOwnProperty.call(this.infoObject, 'filters')) {
            this.infoObject = {
              ...object,
              filters: this.infoObject.filters
             
            };
          }else
            this.infoObject = object
        }
        
        this.requestGet()
      },

      // Get Request to get reviews 
      requestGet() {
        this.loadingTable = true;
          axios.get(`${this.Api}/questions/questions${this.infoObject.filters?.status?.value === "answered" ? "?status=answered" : ""}`, {
            headers: this.headers,
            params: {
              dt_params: this.infoObject.filters?.status?.value === "answered" ? JSON.stringify({
                ...this.infoObject,
                rows: this.rows,
                sortField:"created_at",sortOrder: -1,
                filters: {}


              }) : JSON.stringify({
                ...this.infoObject,
                rows: this.rows,
                sortField:"created_at",sortOrder: -1,
              })
            },
          }).then(data => {
            this.loadingTable = false;
            this.items = data.data.data;
            this.total = data.data.total;
          
            this.addLoading()
          })
      },

      // this method make filter by status
      addLoading() {
        this.items =
          this.items.map((item) => {
            return {
              ...item,
              loading: false,
              fakestatus: item.status === "answered" || item.status === "approved" ?  "Approve" : item.status

            };
          });

         
      },
      
      // Start Events on table
      eventsOnQuestion(data, statusValue) {
        this.items[this.items.indexOf(data)].loading = true;
        let objectUpdate = {
          status: statusValue.toLowerCase()
        };

        axios.put(`${this.Api}/questions/update/${data.question_id}`, objectUpdate , {
          headers: this.headers
        }).then(data=> {
          try{
            if(data.data.message === "Question updated successfully") {
              this.requestGet();
            }

          }catch(err) {
            console.log(err)
          }
        })
      },

      filterOnDamyData(status) {
        if(status === "all")
          this.infoObject = {};
        else{
          this.infoObject ={
            filters:{status:{value:status}}
          }
        }

        this.requestGet()
      },
    }


  });