<template>
  <div :class="{ hovered: !showContent }">
    <button @click.prevent="toggleContent" :aria-expanded="showContent">
      <slot name="title"></slot>
      <svg
        :class="{ revertsvg: showContent }"
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="#6D6D6D"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 8L0.236861 0.5L9.76314 0.499999L5 8Z" fill="#6D6D6D" />
      </svg>
    </button>

    <div v-show="showContent" class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding: 0 20px;
}

.hovered:hover {
  background-color: #fafafa;
}

button {
  padding: 18px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  outline: none;
  transition: 0.3s;
}

button:focus {
  outline: none;
}
.revertsvg {
  transform: rotate(180deg);
}
</style>

<script>
export default {
  data() {
    return {
      showContent: false,
    };
  },

  methods: {
    toggleContent() {
      this.showContent = !this.showContent;
    },
  },
};
</script>
