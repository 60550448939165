import { defineStore } from "pinia";
import axios from 'axios';

export const storeDetails = defineStore("storeDetails", {
    state: () => ({ 
      main_color: "#000000",
      starColor: "#ffc453",
      writeReviewType: "onpage",
      askQuestionType: "onpage",
      appTemplateScript: "https://cdn.lexmodo.com/28/3ca91c6356ae3f95cbce589774fd43e728/js/plugins/QAplugin-64d2ee3c61_gWJEHyEFr.js",

      api: "https://reviews-api.numinix.com/api/admin/custom/",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
      },
    }),

    actions: {

        // Start when make update customization
        upDateCustomization(defaultStatus) {
            let objectInfoCus = {
                themColor: defaultStatus ? "#000": this.main_color,
                starColor: defaultStatus ? "#ffc453": this.starColor,
                writeReviewType: defaultStatus ? "onpage": this.writeReviewType,
                askQuestionType:  defaultStatus ? "onpage": this.askQuestionType,
                appTemplateScript: this.appTemplateScript
            }
            return axios.post(`${this.api}save`,objectInfoCus, {
                    headers: this.headers
            }).then(data => {
                return data
            })
        },

        // Start to get customization
        getCustomization() {
            return axios.get(`${this.api}get`, {
                headers: this.headers
            }).then(data => {
                // Start to update all values style
                this.main_color= data.data.data.themColor;
                this.starColor= data.data.data.starColor;
                this.writeReviewType= data.data.data.writeReviewType;
                this.askQuestionType= data.data.data.askQuestionType;
                return data
            })
        },

        async resetCustomization() {
            await this.upDateCustomization(true)
            await this.getCustomization()
        }

    }



   
})
