<template>
  <section>
    <div class="stats-bar">
      <div class="stats-card" :class="boxColor">
        <p class="stats-card-title">{{ boxName }}</p>
        <div class="stats-card-body">
          <div
            v-show="loadingRequest"
            class="loader"
            style="width: 35px; height: 35px"
          ></div>
          <p v-show="!loadingRequest">
            {{ boxCounter ? boxCounter : 0 }}
          </p>
          <div class="icone" :class="boxColor">
            <slot name="icone"></slot>
          </div>
        </div>

        <div class="stats-card-footer"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BoxOfStatistic",
  props: {
    boxName: String,
    boxCounter: {
      default: 0,
    },
    boxColor: String,
    boxIcone: String,
    loadingRequest: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.stats-bar {
  display: flex;
  gap: 17px;
  box-shadow: 0px 5px 10px #bed8ff;
  border-radius: 8px;
}

.stats-card {
  padding-top: 21px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 295px;
  height: 132px;
  gap: 20px;
  border-radius: 8px;

  .stats-card-title {
    padding-left: 17px;
    padding-right: 17px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
  .stats-card-body {
    padding-left: 17px;
    padding-right: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
    }
  }

  .stats-card-footer {
    height: 16px;
  }
}

.stats-card.yallow {
  background-color: #ffeac2;
  border-bottom: 3px solid #ee962f;
  box-shadow: 0px 5px 19px #ffe5c7;
  .stats-card-footer {
    height: 16px;
    background-color: #ffdfa3;
  }
}

.stats-card.green {
  background: #e8fff1;
  border-bottom: 3px solid #5dcd8a;
  box-shadow: 0px 5px 19px #e8fff1;
  .stats-card-footer {
    background-color: #b3f2cc;
  }
}

.stats-card.blue {
  background: #d5edff;
  border-bottom: 3px solid #1575bf;
  box-shadow: 0px 5px 19px #bed8ff;
  .stats-card-footer {
    background-color: #a3c0ea;
  }
}
.stats-card.orange {
  background: #ffddc4;
  border-bottom: 3px solid #e1772b;
  box-shadow: 0px 5px 19px #ffdddd;
  .stats-card-footer {
    background-color: #ffc59a;
  }
}

.stats-card.red {
  background: #ffdddd;
  border-bottom: 3px solid #d86060;

  box-shadow: 0px 5px 19px #ffdddd;
  .stats-card-footer {
    background-color: #ffb4b4;
  }
}
.stats-card.gray {
  background-color: #ececec;
  border-bottom: 3px solid #bfc2c4;
  box-shadow: 0px 5px 19px #ececec;
  .stats-card-footer {
    background: #d7d7d7;
  }
}
.icone {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.yallow {
    background: #f8bb47;
  }
  &.green {
    background: #5dcd8a;
  }
  &.red {
    background: #d86060;
  }
  &.orange {
    background-color: #e1772b;
  }
  &.blue {
    background-color: #23416d;
  }
  &.gray {
    background-color: #c6c6c6;
  }
}

.loader {
  border: 4px solid #000;
  border-bottom-color: transparent;
  opacity: 0.3;
}
</style>
