<template>
  <button
    name="select-option-product"
    class="select-option-product"
    type="button"
    @click="selectProduct"
  >
    <span v-if="itemsSelects.length === 0">select Product </span>
    <div class="items" v-else>
      <div
        class="product"
        v-for="item in itemsSelects"
        v-bind:key="item.product_image"
      >
        <img :src="item.product_image" alt="" srcset="" />
      </div>
    </div>
  </button>
</template>
<script>
export default {
  name: "ButtonSelect",
  props: {
    itemsSelects: {
      default: [],
    },
  },
  methods: {
    selectProduct() {
      this.$emit("openSelectProductPopUp");
    },
  },
};
</script>

<style lang="scss" scoped>
.select-option-product {
  width: 100%;
  padding: 10px 0;
  text-transform: capitalize;
  color: #1575bf;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #cecece;
  border-radius: 8px;
  transition: 0.2s background-color;

  &:hover {
    background-color: #cecece1f;
  }
}

.items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  .product {
    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
    }
  }
}
</style>
