<template>
  <DataTable
    class="request-table"
    :totalRecords="store.total"
    @page="store.onPage($event)"
    @sort="store.onPage($event)"
    :sortField="store.sortField"
    @filter="store.onPage($event)"
    :value="store.items"
    dataKey="id"
    :lazy="true"
    :loading="store.loadingTable"
    :paginator="true"
    :rows="store.rows"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    responsiveLayout="scroll"
    ref="dataTable"
  >
    <template #loading>
      <div class="loader table-loader"></div>
    </template>
    <Column
      :field="data.field"
      :header="data.header"
      :sortable="data.sort"
      v-for="data in quationsAnser"
      v-bind:key="data"
    >
      <template v-if="data.header === 'Status'" #body="{ data }">
        <div class="status">
          <div class="quation-status" :class="data.status">
            <div class="sercle"></div>
            <span>
              {{ data.status }}
            </span>
          </div>
        </div>
      </template>

      <template v-else-if="data.header === 'Product'" #body="{ data }">
        <a
          class="open-link"
          @click="redirect(data.product.product_id)"
          style="cursor: pointer"
        >
          <span>{{ data.product.product_name }}</span>
          <img src="@/assets/images/icons/open.svg" alt="" />
        </a>
      </template>
      <template v-else-if="data.header === 'Actions'" #body="{ data }">
        <button
          class="unhide"
          v-if="data.status === 'hidden'"
          @click="store.eventsOnQuestion(data, 'approve')"
        >
          <img src="@/assets/images/icons/unhide.svg" />
          <span>Unhide</span>
        </button>
        <Dropdown
          v-else
          v-model="data.fakestatus"
          :options="action_values"
          :loading="data.loading"
          id=""
          optionLabel="name"
          optionValue="value"
          style="width: 120px; border: 1px solid #d8d8d8; border-radius: 8px"
          @change="store.eventsOnQuestion(data, data.fakestatus)"
        ></Dropdown>
      </template>

      <template v-else-if="data.header === 'Answers'" #body="{ data }">
        <button
          @click="showAnswers(data)"
          style="
            min-width: 112px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 41px;
          "
        >
          <span style="font-size: 13px">Show Answers</span>
        </button>
      </template>
    </Column>

    <template #empty>
      <empty-big-table message="No reviews found"></empty-big-table>
    </template>
  </DataTable>

  <!-- Start PopUp Answer -->
  <pop-up-answer
    :show-popup="showAnswersPopupStatus"
    :data-questions="dataQuestions"
    @canselPopup="cancelPopup"
    @update-loading-question="loadingUpDateQuestion"
  ></pop-up-answer>
</template>
<script>
// Start Answer  Poup
import EmptyBigTable from "@/components/UI/EmptyBigTable.vue";
import { useTableStore } from "@/service/QuestionStore/QuationAndAnswerTable";
import { useQuestionsEvents } from "@/service/QuestionStore/QuestionsEventsPopup";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import PopUpAnswer from "@/views/Questions/components/PopUpAnswer.vue";
import quationsAnser from "@/views/Questions/quationsAnser.json";

export default {
  name: "questionTableComponent",
  components: {
    DataTable,
    EmptyBigTable,
    Column,
    Dropdown,
    PopUpAnswer,
  },

  data() {
    return {
      store: useTableStore(),
      storeEventsPopUp: useQuestionsEvents(),
      quationsAnser: quationsAnser,
      showAnswersPopupStatus: false,
      dataQuestions: {
        answers: [],
      },
      dataAnswers: [],
      action: "Approve",
      action_values: [
        { name: "Approve", value: "Approve" },
        { name: "Hide", value: "Hide" },
        { name: "Delete", value: "deleted" },
        { name: "None", value: "pending" },
      ],
    };
  },

  mounted() {
    this.store.onPage();
  },

  methods: {
    showAnswers(data) {
      this.dataQuestions = data;
      this.addLoading(data);
      this.dataQuestions.answers = this.dataAnswers;
      this.showAnswersPopupStatus = true;
    },
    loadingUpDateQuestion(qu) {
      this.dataQuestions = qu;
    },
    // Add Loading to Array
    addLoading(data) {
      this.dataQuestions.loadingDelete = false;
      this.dataQuestions.loadingHidden = false;
      this.dataQuestions.loadingPublished = false;

      let answers = data.answers;
      if (data.answers) {
        this.dataAnswers = answers.map((comment) => {
          return {
            ...comment,
            loadingDelete: false,
            loadingHidden: false,
            loadingPublished: false,
          };
        });
      }
    },

    cancelPopup() {
      this.showAnswersPopupStatus = false;
    },
    redirect(id) {
      window.parent.location.href = `https://admin.lexmodo.com/products/${id}`;
    },
  },
  // START SEARCH ON PRODUCTS
  makeFilter() {
    this.storeReviewTable.onPage();
  },
};
</script>

<style lang="scss">
.p-datatable-tbody {
  min-height: 750px;
}
</style>
