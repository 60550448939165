import axios from 'axios';
import { defineStore } from 'pinia';
import { Reviews } from "@/service/Reviews";
import { useTableReviewsStore } from "@/service/TableProductsReview";


export const useReviewsEvents = defineStore({
    id: 'useReviewsEvents',
    state: () => ({
      Api: "https://reviews-api.numinix.com/api/admin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
      },
      showPopUp: false,
      product_id: "",
      reviewsData: [],
      rows: 3,
      totalReviews: 0
    }),


    getters: {
     showReviews() {
      return this.reviewsData.map((comment) => {
        return {
          ...comment,
          loadingDelete: false,
          loadingHidden: false,
          loadingPublished: false
        }
      })
     }
    },
    actions: {

      // Actions on PopUp 
       async ShowReview(product_id) {
          this.product_id = product_id;
          this.rows = 3;
          this.loadingWhenClickShowReviews(this.product_id, true)
          this.getAllReviewsForThiProduct()
          
        },

        hidePopUp() {
          this.showPopUp = false;
        },
        loadingWhenClickShowReviews(product_id,status) {
          const storeTable = useTableReviewsStore();
          storeTable.itemsReviews.forEach(el => {
            if(el.product_id === product_id){
              el.loading = status
            }
          })
        },

      // Start to make request to get review to this products
        getAllReviewsForThiProduct() {
          
          axios.get(`${this.Api}/reviews/${this.product_id}`, {
            headers: this.headers,
            params: {
              dt_params: JSON.stringify({
                rows: this.rows
              })
            }
           
          }).then(data => {
            this.loadingWhenClickShowReviews(this.product_id, false);
            this.showPopUp = true;

            try{
              this.reviewsData = data.data.data;
              this.totalReviews =  data.data.total;
              const reviews = Reviews();
              reviews.getStatistics("All time");
              this.addLoading();
            }catch(err) {
              console.log(err, "changed Api data")
            }
  
          })
       
      },

      // add loading to array
      addLoading() {
        this.reviewsData = this.reviewsData.map((comment) => {
          return {
            ...comment,
            loadingDelete: false,
            loadingHidden: false,
            loadingPublished: false
          }
        })
      },

      // Start show more
      showMoreReviews(length) {
        this.rows += length;
        this.getAllReviewsForThiProduct()
      },


      //Start actions reviews  (HIDE, APPROVE)
      actionsOnReview(id, status) {
        if(status === "delete"){
          this.deleteAction(id)
          return ""
        }
        // This else 
        let objectUpdate = {
          status: status
        };
        axios.put(`https://reviews-api.numinix.com/api/admin/reviews/${id}`, objectUpdate , {
          headers: this.headers
        }).then(data=> {
          try{
            if(data.data.status === "success") {
              this.getAllReviewsForThiProduct()
              const storeTable = useTableReviewsStore();
              storeTable.requestGet();
            }
          }catch(err) {
              console.log(err)
          }
        })
      },


      // Start delete action
      deleteAction(id) {
        axios.delete(`${this.Api}/reviews/${id}` , {
          headers: this.headers
        }).then(data=> {
          try{
            if(data.data.status === "success") {
              this.getAllReviewsForThiProduct();
              const storeTable = useTableReviewsStore();
              storeTable.requestGet();
            }
          }catch(err) {
            console.log(err)
          }
        })
      }


    },

   

  });