<template>
  <div class="empty-section">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "EmpityTableStatistics",
  props: ["message"],
};
</script>

<style lang="scss">
.empty-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  p {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
