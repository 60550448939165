<template>
  <div class="nav-filters">
    <ul>
      <li
        v-for="label in filters"
        v-bind:key="label"
        @click="changeFilter(label)"
      >
        <span class="span" v-bind:class="label.class">{{
          label.labelName
        }}</span>
      </li>
    </ul>
  </div>
</template>

<!-- start script -->
<script>
import FilterLabels from "@/components/QuestionsAnswers/filterLabels.json";
import { useTableStore } from "@/service/QuestionStore/QuationAndAnswerTable.js";

export default {
  name: "FilterLabel",

  data() {
    return {
      filters: FilterLabels,
      store: useTableStore(),
    };
  },

  methods: {
    changeFilter(valueTargt) {
      this.filters.forEach((objectFilter) => {
        objectFilter.class = "";
        if (objectFilter.value === valueTargt.value) {
          objectFilter.class = "active";
          this.requestToStore(valueTargt);
        }
      });
    },
    requestToStore(valueTargt) {
      this.store.filterOnDamyData(valueTargt.value);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/helper/variables";

.nav-filters {
  padding: 0 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
  ul {
    padding: 0px 18px;
    list-style: none;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid #cecece57;
    border: 1px solid #edeeef;
  }
}

// ul li:last-of-type {
//   margin-left: auto;
// }

ul .span {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px 15px;
}
ul li {
  position: relative;
  bottom: -2px;
}
ul li .span {
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-bottom: 3px solid #09a59b00;
  font-weight: 400;
  padding: 14px 20px;
}
ul .span {
  transition: 0.2s;
  font-weight: 400;
}
ul .span:hover {
  color: #000000c9;
  svg {
    fill: $main-color;
  }
}
ul .span:hover .span {
  color: #09a59a;
}

.active-tab {
  border-bottom: 3px solid #ea7e00;
  color: #ea7e00;
}
.active-tab svg {
  fill: #ea7e00;
}

li .span {
  text-decoration: none;
  color: #6d6d6d;
}
.active {
  text-decoration: none !important;
  color: #000 !important;
  border-bottom: 3px solid $main-color !important;
  svg {
    fill: $main-color;
  }
}
.body-content {
  border-top: 0;
}
</style>
