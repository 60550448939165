<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.59789 1.8541C10.1966 0.0114764 12.8034 0.0114797 13.4021 1.8541L14.6432 5.67376C14.9109 6.49781 15.6789 7.05573 16.5453 7.05573H20.5615C22.499 7.05573 23.3045 9.53496 21.7371 10.6738L18.4879 13.0344C17.7869 13.5437 17.4936 14.4465 17.7614 15.2705L19.0024 19.0902C19.6012 20.9328 17.4922 22.465 15.9248 21.3262L12.6756 18.9656C11.9746 18.4563 11.0254 18.4563 10.3244 18.9656L7.07523 21.3262C5.5078 22.465 3.39885 20.9328 3.99755 19.0902L5.23863 15.2705C5.50638 14.4465 5.21306 13.5437 4.51209 13.0344L1.26289 10.6738C-0.304538 9.53496 0.501016 7.05573 2.43846 7.05573H6.45469C7.32115 7.05573 8.08906 6.49781 8.3568 5.67376L9.59789 1.8541Z"
      :fill="storeD.starColor"
    />
  </svg>
</template>

<script>
import { storeDetails } from "@/service/StoreDetails";
export default {
  name: "starDiv",
  data() {
    return {
      storeD: storeDetails(),
      fillColor: "#FFC453",
    };
  },
};
</script>
