<template>
  <Dropdown
    v-model="valueDate"
    :options="arrayOfDate"
    optionLabel="name"
    optionValue="value"
    :loading="statusLoading"
    style="width: 170px; border: 1px solid #d8d8d8; border-radius: 8px"
    @change="$emit('changeDateDashbord', valueDate)"
  >
    <template #value="slotProps">
      <div class="p-dropdown-car-value d-flex g-10" v-if="slotProps.name">
        <img src="@/assets/images/icons/date-icone.svg" alt="" srcset="" />
        <span>{{ slotProps.name }}</span>
      </div>
    </template>
  </Dropdown>
</template>

<style></style>
<script>
import Dropdown from "primevue/dropdown";
export default {
  name: "DateDropDown",
  components: {
    Dropdown,
  },
  props: {
    valueDateProp: {
      default: "All time",
    },
    statusLoadingProp: {
      default: false,
    },
  },
  data() {
    return {
      statusLoading: this.statusLoadingProp,
      valueDate: this.valueDateProp,
      // start array of dates
      arrayOfDate: [
        {
          name: "Last 7 days",
          value: "7",
        },
        {
          name: "Last 30 days",
          value: "30",
        },
        {
          name: "Last 90 days",
          value: "90",
        },
        {
          name: "All time",
          value: "All time",
        },
      ],
    };
  },
  mounted() {
    this.$watch("statusLoadingProp", (newVal) => {
      this.statusLoading = newVal;
    });
  },
};
</script>
