import { createRouter, createWebHistory } from "vue-router";
import DashBoard from "@/views/Questions/DashBoard";
import QuestionsAnswers from "@/views/Questions/QuestionsAnswers";
import ReviewsDashboard from "@/views/Reviews/ReviewsDashboard.vue";
import ThemeView from "@/views/Theme/Theme.vue"

const routes = [
    { path: "/", redirect : '/dashBoard'},
    { path: "/dashBoard", component: DashBoard},
    { path: "/questions", component: QuestionsAnswers},
    { path: "/reviews", component: ReviewsDashboard},
    { path: "/theme", component: ThemeView},

]


const router = createRouter({
    routes,
    history: createWebHistory(),
    linkActiveClass: "active"
})

export default router

