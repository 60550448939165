<template>
  <!-- Darg And Drop to sorting -->
  <div class="sort-drop-down">
    <Dropdown
      v-model="sortBy"
      :options="dataSorting"
      @change="$emit('changeSorting', sortBy)"
      optionLabel="name"
      optionValue="value"
      style="width: 86px; border: 1px solid #d8d8d8; border-radius: 8px"
    >
      <template #value="slotProps">
        <div class="sorting-table" v-if="slotProps.value">
          <img src="@/assets/images/icons/sorting.svg" />
          <span>Sort</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  name: "DropDownSorting",
  emits: ["changeSorting"],
  props: {
    dataSorting: {
      default: [
        { name: "Product Name", value: "Product Name" },
        { name: "Status", value: "Status" },
      ],
    },
  },
  data() {
    return {
      sortBy: "defualt",
    };
  },
  components: {
    Dropdown,
  },
  methods: {},
};
</script>

<style lang="scss">
.sort-drop-down {
  .p-dropdown-trigger {
    display: none !important;
  }

  .sorting-table {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 8px;
  }
}
</style>
