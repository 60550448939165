<!-- This Copmponent to make new review  -->
<template>
  <pop-up-form
    button-name="Select product"
    class="select-products"
    title-popup="Search product to review"
    :disabled-status="!itemsSelect.length > 0"
    :show-popup="showPopupsSearch"
    @submitForm="selectProductDone"
    @cansel-popup="cancelPopUpAddReview"
  >
    <template #body>
      <div class="input-search">
        <i class="">
          <img
            src="@/assets/images/icons/search.svg"
            alt=""
            srcset=""
            style="opacity: 0.7"
          />
        </i>
        <InputText
          type="text"
          name="name"
          placeholder="Search product"
          @input="searchProducts($event)"
        />
      </div>
      <div class="products-select">
        <!-- Start Loading -->
        <div class="loading-section">
          <div
            class="loader"
            style="width: 30px; height: 30px"
            v-show="loadingStatus"
          ></div>
        </div>
        <div
          class="product-row"
          v-show="!loadingStatus"
          v-for="(item, index) in dataProductsSearch"
          v-bind:key="item"
          @click="selectThisProduct(index)"
        >
          <div class="chosen">
            <div class="img">
              <img :src="item.product_image" alt="" />
            </div>
            <div class="product_name">
              <h5>{{ item.product_name }}</h5>
            </div>
          </div>
          <button class="select" type="button">
            <img
              src="@/assets/images/icons/select.svg"
              alt=""
              srcset=""
              v-show="item.active"
            />
          </button>
        </div>
      </div>
    </template>
  </pop-up-form>
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import InputText from "primevue/inputtext";
import { Reviews } from "@/service/Reviews";

export default {
  name: "AddNewReview",
  props: {
    showPopupsSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valRating: 0,
      dataProductsSearch: [],
      itemsSelect: [],
      store: Reviews(),
      loadingStatus: false,
    };
  },
  components: {
    PopUpForm,
    InputText,
    // FileUpload,
  },
  methods: {
    // Start Search On Products
    searchProducts(e) {
      this.loadingStatus = true;
      this.store
        .searchOnStoreProducts(e.target.value)
        .then((data) => {
          this.dataProductsSearch = data.data;
          this.loadingStatus = false;
        })
        .catch(() => {
          this.loadingStatus = false;
        });
    },

    cancelPopUpAddReview() {
      this.$emit("cancelSelectProduct", false);
      this.valRating = 0;
    },

    // Event select this product
    selectThisProduct(index) {
      let conditionHasToHasThisItem = this.itemsSelect.some((item) => {
        return item.product_id === this.dataProductsSearch[index].product_id;
      });
      if (conditionHasToHasThisItem) {
        this.deleteObjectByValue(
          this.itemsSelect,
          "product_id",
          this.dataProductsSearch[index].product_id
        );
        this.dataProductsSearch[index].active = false;
      } else {
        this.itemsSelect.push(this.dataProductsSearch[index]);
        this.dataProductsSearch[index].active = true;
      }
    },

    //Delete object by value
    deleteObjectByValue(array, property, value) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][property] === value) {
          array.splice(i, 1);
          break;
        }
      }
      return array;
    },

    selectProductDone() {
      // this.store.selectProducts(this.itemsSelect);
      this.$emit("selectProductsSubmit", this.itemsSelect);
      this.itemsSelect = [];
      this.dataProductsSearch = [];
    },
  },

  computed: {
    searchItemsToActive() {
      let newArray = [];
      this.dataProductsSearch.forEach((item) => {
        item.active = false;
        newArray.push(item);
      });
      return newArray;
    },
  },
};
</script>

<style lang="scss">
.select-products {
  min-width: 800px;
  max-height: 700px;
}
.p-dialog .p-dialog-footer button:first-child {
  display: none;
}
.select-products {
  .p-dialog-content {
    padding: 0 !important;
  }
  .p-dialog-header h3 {
    font-size: 18px;
  }
}
.input-search {
  margin-top: 5px;
  position: relative;
  margin: 10px 22px 0 22px;
  input {
    background-color: #fbfbfb !important;
    color: #000;
    width: 100%;
    padding-left: 37px;
  }
  i {
    position: absolute;
    top: 30%;
    left: 10px;
  }
}

.products-select {
  margin-bottom: 35px;
  .product-row {
    display: flex;
    justify-content: space-between;
    padding: 7px 20px;
    border-bottom: 1px solid #e7e7e7;
    cursor: pointer;
    &:hover {
      background-color: #f0f9ff;
    }
    .img {
      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
  .chosen {
    display: flex;
    gap: 15px;
    align-items: center;
  }
}

.loading-section {
  padding: 14px 0;
  .loader {
    border: 4px solid rgb(35, 65, 109) !important;
    border-bottom-color: transparent !important;
  }
}
</style>
