<template>
  <Dialog
    v-model:visible="showPopups"
    class="pop-up-create"
    @update:visible="handleClose"
  >
    <template #header>
      <h3>{{ titlePopup }}</h3>
    </template>
    <form action="">
      <div class="body-popup">
        <slot name="body"></slot>
      </div>
    </form>
    <template #footer>
      <Button
        label="Cansel"
        icon="pi pi-times"
        class="p-button-text"
        @click="canselPopup()"
      />

      <Button
        :label="buttonName"
        @click="submitForm()"
        :disabled="disabledStatus"
        :loading="loadingButton"
        v-if="showSubmitButton"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
export default {
  name: "CreateQuestion",
  components: {
    Dialog,
    Button,
  },
  props: {
    titlePopup: {
      typeof: String,
      require: true,
    },
    buttonName: {
      typeof: String,
    },
    canselButtonName: {
      typeof: Boolean,
    },
    showPopup: {
      default: false,
    },
    disabledStatus: {
      default: false,
    },
    loadingButton: {
      default: false,
    },
    showSubmitButton: {
      default: true,
    },
  },
  data() {
    return {
      showPopups: this.showPopup,
    };
  },
  mounted() {},
  watch: {
    showPopup(newValue) {
      this.showPopups = newValue;
    },
  },
  methods: {
    canselPopup() {
      this.$emit("canselPopup");
    },
    handleClose() {
      this.$emit("canselPopup");
    },
    submitForm() {
      this.$emit("submitForm");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/helper/variables";

// start popup
.p-dialog {
  min-width: 612px;
  border-radius: 10px;
  p {
    font-size: 14px;
    color: #6d6d6d;
    font-weight: 300;
  }
  .p-dialog-header {
    padding-bottom: 0;
  }
}
.p-dialog .p-dialog-footer button:first-child {
  color: #1575bf;
  border: 1px solid #1575bf38;
  border-radius: 5px;
}
.p-dialog .p-dialog-footer button:last-child {
  background-color: #ff684d;
  border: none;
  margin: 0;
  padding: 10px 20px;
  border-radius: 5px;
}

// start popup
.pop-up-create,
.edit-wishlsit {
  h3 {
    font-weight: 700;
    color: #202223;
  }
  .p-dialog-header {
    padding-bottom: 22px;
  }
  .p-dialog-content {
    padding-top: 3px;
  }
  .body-popup {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      color: $main-fonts-color;
      font-size: 14px;
      font-weight: 400;
      &.privacy {
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
    //serch icone
    .serch {
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr;
      background-color: #fbfbfb;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      .img-icone {
        padding: 0 10px;
      }
      input {
        border-left: 0;

        border: none;
      }
    }
    input,
    textarea {
      background-color: #fbfbfb;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
    }
    .chose {
      display: flex;
      gap: 5px;
      cursor: pointer;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .p-dialog-footer button:last-child {
    background-color: $main-color !important;
  }
  .chose-container {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .p-dialog-header {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
  }
  .p-dialog-footer {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
  }
}
.p-dialog .p-dialog-footer button:first-child {
  color: $main-color;
}
.pop-up-create {
  border-radius: 12px !important;
}
</style>
