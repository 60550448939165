import axios from 'axios';
import { defineStore } from 'pinia';
import { useTableStore } from "@/service/QuestionStore/QuationAndAnswerTable";


export const useQuestionsEvents = defineStore({
    id: 'useQuestionsEvents',
    state: () => ({
      Api: "https://reviews-api.numinix.com/api/admin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
      },
      showPopUp: false,
      product_id: "",
      QuestionData: {},
      rows: 3,
      totalQuestions: 0
    }),


    getters: {
     showQuestions() {
      return this.QuestionData.map((comment) => {
        return {
          ...comment,
          loadingDelete: false,
          loadingHidden: false,
          loadingPublished: false
        }
      })
     }
    },


    actions: {

      // Actions on PopUp 
        ShowAnswers(itemObject) {
            this.QuestionData = itemObject;
            console.log(this.QuestionData)
        },

        hidePopUp() {
          this.showPopUp = false;
        },

      // Add Loading to Array
      addLoading() {
        this.QuestionData = this.QuestionData.map((comment) => {
          return {
            ...comment,
            loadingDelete: false,
            loadingHidden: false,
            loadingPublished: false
          }
        })
      },



      //Start actions Question  (HIDE, APPROVE)
      actionsOnQuestion(id, status) {
        // This else 
        let objectUpdate = {
          status: status
        };
        return axios.put(`https://reviews-api.numinix.com/api/admin/questions/update/${id}`, objectUpdate , {
          headers: this.headers
        }).then(data=> {
          try{
            if(data.data.message === "Question updated successfully") {
              const storeTable = useTableStore();
              storeTable.requestGet();
            }
          }catch(err) {
              console.log(err)
          }
        })
      },


      //start add answer 
      addAnswerToQuestion(objectAnswer) {
        return axios.post(`https://reviews-api.numinix.com/api/admin/questions/answer`, objectAnswer , {
          headers: this.headers
        }).then(data=> {
          try{
            if(data.data.message === "answer added successfully") {
              const storeTable = useTableStore();
              storeTable.requestGet();
              return "answer added successfully"
            }
          }catch(err) {
              console.log(err)
          }
        })
      }

    },

   

  });