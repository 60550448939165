import axios from 'axios';
import { defineStore } from 'pinia';
import { storeMessages } from "@/service/storeMessages";

export const DashboardQuestions = defineStore({
    id: 'questionsDashboardStore',
    state: () => ({
        Api: "https://reviews-api.numinix.com/api/admin",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
        },
        sMessages: storeMessages(),
        loadingRequestStatistics: true,
        filterDateLoading: false,
        statistics: [
            {
                status:"pending",
                total: 0
            },
            {
                status:"answered",
                total: 0
            },
            {
                status:"approved",
                total: 0
            },
           
            {
                status:"hidden",
                total: 0
            },
            {
                status: "deleted",
                taotal: 0
            }
        ],
    }),

    actions: {
        // Start to create new question
        setNewQuestion(obj) {
            return axios.post(`${this.Api}/questions/store`, obj, {
                headers: this.headers
            }).then(data => {
                if(data.data.message === "Question added successfully") {
                    
                    this.sMessages.messages= [];
                    setTimeout(() => {
                        this.sMessages.messages.push( { severity: "success", content: "Done Question Added" });
                    }, 100);

                    setTimeout(() => {
                        this.sMessages.messages = [];
                    }, 4000);
                    // Start to refresh reviews table and reviews statistics
                    return "success"
                }
            }).catch(err => {
                this.handleErrorToPrimeVue(err.response.data.errors);
            })
        },

        // Handle Error to Prime Vue
        handleErrorToPrimeVue(errors) {
            this.sMessages.messages = [];
            setTimeout(() => {
                for (const key in errors) {
                    const element = errors[key];
                    this.sMessages.messages.push( { severity: "error", content: element[0],  life: 3000 });
                }
            })
            setTimeout(() => {
                this.sMessages.messages = [];
            }, 4000);
           
        },

         // Start get Statistics
         getStatistics(days) {
            return axios.get(`${this.Api}/questions/dashboard/statistics?days=${days !== "All time" ? days : ''}`,{
                headers: this.headers
            }).then(data => {
                try {
                    data.data.forEach(child => {
                    
                        this.statistics.forEach((mainChild, index) => {
                         if(child.status === mainChild.status){
                            this.statistics[index] = child;
                         }
                        })
                     })
                     this.filterDateLoading = false;
                     this.loadingRequestStatistics = false;

                }catch (error) {
                    console.error(error, 'changes in stricture data from api');
                }
            }).catch(err => {
                this.filterDateLoading = false;
                this.loadingRequestStatistics = false;
                try{
                    if(err.response.status === 401) {
                        // window.location.reload()
                    }
                }catch {
                    console.error(err, 'changes in stricture data from api');
                }
            })

        },





       // Start get 3 Tables for  statistics 
      getStatisticsTables(type, objectInfoTable) {
        return axios.get(this.Api + `/questions/dashboard/${type}`, {
            headers: this.headers,
            params: {
              dt_params: JSON.stringify({
                ...objectInfoTable,
              })
            }
        }).then(data => {
            return data.data
        })
      }

    }

  });