<template>
  <section class="container">
    <div class="them body-content">
      <div class="header-page">
        <div class="title-details">
          <h2>Customize Reviews Theme</h2>
          <p>Choose how to customize your reviews section.</p>
        </div>
        <div class="unsaved">
          <span>Unsaved changes</span>
        </div>
      </div>
      <div class="row">
        <!-- Start Customization by admin -->
        <div class="customization">
          <div class="info">
            <h4>Product Reviews</h4>
            <h6>
              Subscription message is shown to users after they subscribe.
            </h6>
          </div>
          <div class="customization-components">
            <base-accordion class="base">
              <template #title>
                <h6>Colors</h6>
              </template>
              <template #content>
                <!-- Start Color Section -->
                <div class="section-color">
                  <label for=""> Theme Color </label>
                  <div class="content-base">
                    <color-input
                      v-model="storeD.main_color"
                      @change-color-value="changeColorThem"
                    >
                    </color-input>
                  </div>
                </div>
                <div class="section-color">
                  <label for="">Star Rating Color </label>
                  <div class="content-base">
                    <color-input
                      v-model="storeD.starColor"
                      @change-color-value="changeColorStarColor"
                    >
                    </color-input>
                  </div>
                </div>
              </template>
            </base-accordion>

            <!-- Start Write a Review  -->
            <base-accordion class="base">
              <template #title>
                <h6>Write a Review</h6>
              </template>
              <template #content>
                <!-- Start reviews Section -->
                <div class="section-content">
                  <div class="question">
                    <label for="" class="title-label">Write a Review</label>

                    <div class="answer-chose">
                      <div class="chose-review">
                        <RadioButton
                          v-model="writeReviewStyle"
                          inputId="onpage"
                          name="writeReviewStyle"
                          value="on page"
                        />
                        <label for="onpage" class="ml-2">On page</label>
                      </div>
                      <div class="chose-review">
                        <RadioButton
                          v-model="writeReviewStyle"
                          inputId="popup"
                          name="writeReviewStyle"
                          value="popup"
                        />
                        <label for="popup" class="ml-2">Popup</label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </base-accordion>

            <!-- Start Ask a Question -->
            <base-accordion class="base">
              <template #title>
                <h6>Ask a Question</h6>
              </template>
              <template #content>
                <!-- Start reviews Section -->
                <div class="section-content">
                  <div class="question">
                    <label for="" class="title-label"
                      >Ask a Question Form</label
                    >

                    <div class="answer-chose">
                      <div class="chose-review">
                        <RadioButton
                          v-model="writeAskAndQuestion"
                          inputId="onpageQuestion"
                          name="writeAskAndQuestion"
                          value="on page"
                        />
                        <label for="onpageQuestion" class="ml-2">On page</label>
                      </div>
                      <div class="chose-review">
                        <RadioButton
                          v-model="writeAskAndQuestion"
                          inputId="popupQuestion"
                          name="writeAskAndQuestion"
                          value="popup"
                        />
                        <label for="popupQuestion" class="ml-2">Popup</label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </base-accordion>

            <base-accordion class="base">
              <template #title>
                <h6>Add To Template</h6>
              </template>
              <template #content>
                <!-- Start Color Section -->
                <div class="section-color">
                  <label for=""> Add To Template </label>
                  <div class="content-base">
                    <pre>
<code class="language-html">
  <pre>
<code class="language-html">
&lt;div id="apptemplate" product_id="&lt;%= product.ProductId %&gt;" store_id="&lt;%= client_id %&gt;" logged_in="false" star_color="red" main_color="red"&gt;&lt;/div&gt;
&lt;script src="https://cdn.lexmodo.com/28/3ca91c6356ae3f95cbce589774fd43e728/js/plugins/QAsetupPlugin-d6cab5dc76_REjq4wmc7.js" &gt;&lt;/script&gt;
</code>
</pre>
</code>
</pre>
                  </div>
                </div>
              </template>
            </base-accordion>
          </div>
          <!-- Start actions -->

          <div class="actions-on-template-app">
            <Button
              class="button button-reset"
              label="Reset"
              @click="resetThem()"
            />
            <Button class="button" label="Save" @click="SaveTemplate()" />
          </div>
        </div>

        <!-- Start store theme -->
        <div
          class="store-theme"
          :style="{
            opacity: loadingThem,
          }"
        >
          <headerProductReviews></headerProductReviews>
        </div>
      </div>
    </div>
  </section>

  <!-- PopUP confirm settings reviews -->
  <pop-up-form
    button-name="save"
    class="select-products"
    title-popup="Customize Theme"
    :disabled-status="false"
    :show-popup="showPopupsConfirmThem"
    @submitForm="saveConfirm"
    @cansel-popup="cancelPopupsConfirmThem"
    :loadingButton="loadingButtonThem"
  >
    <template #body>
      <div class="paragraph-confirm-mess">
        <p>Are you sure about the changes?</p>
      </div>
    </template>
  </pop-up-form>
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import BaseAccordion from "@/components/UI/BaseAccordion.vue";
import ColorInput from "@/components/UI/ColorInput.vue";
import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";
import headerProductReviews from "@/views/Theme/templateApp/headerProductReviews.vue";
import { storeDetails } from "@/service/StoreDetails";
import { storeMessages } from "@/service/storeMessages";
export default {
  name: "ThemeView",
  components: {
    BaseAccordion,
    ColorInput,
    RadioButton,
    Button,
    headerProductReviews,
    PopUpForm,
  },
  emits: ["routeName", "mainButton"],

  data() {
    return {
      writeReviewStyle: "on page",
      writeAskAndQuestion: "on page",
      storeD: storeDetails(),
      showPopupsConfirmThem: false,
      loadingButtonThem: false,
      storeM: storeMessages(),
      loadingThem: 0.2,
    };
  },

  beforeMount() {
    this.storeD.getCustomization().then(() => {
      this.loadingThem = 1;
    });
  },
  mounted() {
    this.$emit("mainButton", "Review");
  },
  methods: {
    changeColorStarColor(value) {
      this.storeD.starColor = value;
    },

    changeColorThem(value) {
      this.storeD.main_color = value;
    },

    // start SaveTemplate settings
    SaveTemplate() {
      this.showPopupsConfirmThem = true;
    },

    cancelPopupsConfirmThem() {
      this.showPopupsConfirmThem = false;
    },

    //Start method to save customization settings
    saveConfirm() {
      this.loadingButtonThem = true;
      this.storeD
        .upDateCustomization(false)
        .then((data) => {
          this.loadingButtonThem = false;
          /* eslint-disable */
          if ((data.data.status = "success")) {
            this.storeM.messages = [];
            this.storeM.messages.push({
              severity: "success",
              content: "Done Question Added",
            });
            this.showPopupsConfirmThem = false;
          }
        })
        .catch((err) => {
          this.loadingButtonThem = false;
          console.log(err);
        });
    },

    resetThem() {
      this.storeD.resetCustomization();
    },
  },
};
</script>

<style lang="scss">
.them {
  h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    color: #202223;
  }
  p {
    font-size: 14px;
    color: #6d6d6d;
  }

  // start header-page
  .header-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .unsaved {
      span {
        font-size: 14px;
        color: #202223;
        cursor: pointer;
      }
    }
  }

  // start row
  .row {
    display: grid;
    grid-template-columns: 48% 49%;
    gap: 3%;
  }

  // start store-theme
  .store-theme {
    background-color: #f2f5fa1f;
    min-height: 80vh;
    border-radius: 10px;
    border: 1px solid #d8d8d8;
    margin-top: 20px;
  }
  .customization {
    margin-top: 50px;
    color: #202223;
    .info {
      margin-bottom: 21px;
      h4 {
        font-size: 16px;
        font-weight: 500;
      }
      h6 {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .customization-components {
      .base {
        border-bottom: 1px solid #d8d8d8;
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        label {
          font-size: 14px;
          color: #202223;
          margin-bottom: 14px;
        }
        .content-base {
          padding: 15px 0;
          padding-top: 0px;
        }
      }
    }
  }
}

// start section-content
.section-content {
  .title-label {
    margin-bottom: 15px;
    display: flex;
  }
  .question {
    padding-bottom: 18px;
  }
  .answer-chose {
    display: flex;
    flex-direction: column;
    gap: 6px;
    label {
      cursor: pointer;
    }
  }
}

// edit border radio

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #2196f3;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #2196f3 !important;
  background: #2196f3 !important;
}

// start edit inputs

.them {
  .p-button {
    background-color: #23416d;
    border: none !important;
    padding: 0.7rem 1.25rem;
    font-weight: 600 !important;
    font-size: 14px;
    &:hover {
      border: none !important;
      background-color: #11294d !important;
    }
    &.button-reset {
      border: 1px solid #cecece !important;
      color: #c83e25 !important;
      background-color: #fff;
      &:hover {
        background-color: #fff !important;
      }
    }
  }
}

// start actions-on-template-app
.actions-on-template-app {
  margin-top: 15px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.paragraph-confirm-mess {
  padding: 0 26px;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
}

// start code style

pre {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  padding: 12px;
  overflow-x: auto;
}

code {
  font-family: "Courier New", Courier, monospace;
}
</style>
