<template>
  <div class="container">
    <nav>
      <ul ref="ul">
        <li>
          <router-link to="/dashboard">
            <span>Q&A Dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link to="/questions">
            <span> </span><span>Questions</span>
          </router-link>
        </li>
        <li>
          <router-link to="/reviews">
            <span> </span><span>Reviews</span>
          </router-link>
        </li>
        <li>
          <router-link to="/theme">
            <span> </span><span>Theme</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "SiteBar",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/helper/variables";

nav {
  padding: 0 0;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
}

ul {
  padding: 0px 18px;

  list-style: none;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 23px;
  border-bottom: 1px solid #cecece;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid #edeeef;
}

// ul li:last-of-type {
//   margin-left: auto;
// }

ul a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px 15px;
}
ul li {
  position: relative;
  bottom: -2px;
}
ul li a {
  display: flex;
  align-items: center;
  transition: 0.2s;
  border-bottom: 3px solid #09a59b00;
  font-weight: 400;
}
ul a {
  transition: 0.2s;
  font-weight: 400;
}
ul a:hover {
  color: #000000c9;
  svg {
    fill: $main-color;
  }
}
ul a:hover a {
  color: #09a59a;
}

.active-tab {
  border-bottom: 3px solid #ea7e00;
  color: #ea7e00;
}
.active-tab svg {
  fill: #ea7e00;
}
// ul li span {
//   margin-right: 10px;
// }

li a {
  text-decoration: none;
  color: #6d6d6d;
}
.active {
  text-decoration: none;
  color: $main-color;
  border-bottom: 3px solid $main-color;
  svg {
    fill: $main-color;
  }
}
</style>
