<template>
  <div class="table-statistics">
    <h4>{{ titleTable }}</h4>
    <DataTable
      class="dashboard-table"
      :paginator="true"
      :lazy="true"
      :loading="loadingTable"
      @page="onPage($event)"
      :value="dataItems.data"
      :rows="9"
      :totalRecords="dataItems.total"
      paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
      responsiveLayout="scroll"
      currentPageReportTemplate="{totalRecords} items"
    >
      <template #loading>
        <div class="loader table-loader"></div>
      </template>
      <Column field="product_name" header="Product Name">
        <template #body="slotProps">
          <a
            class="text-1-lenght"
            style="color: #23416d; cursor: pointer"
            @click="redirect(slotProps.data.product_id)"
            >{{ slotProps.data.product_name }}
          </a>
        </template>
      </Column>
      <Column field="all_reviews_count" :header="headC"></Column>

      <template #empty>
        <empity-table-statistics
          message="No reviewed products found"
        ></empity-table-statistics>
      </template>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import EmpityTableStatistics from "@/components/UI/EmpityTableStatistics.vue";
export default {
  name: "StatisticsTable",

  props: {
    titleTable: String,
    dataItems: {
      default: {
        total: 0,
        data: [],
      },
    },
    totalLength: Number,
    type: String,
    headC: String,
    loadingTable: Boolean,
  },

  mounted() {
    this.$emit("getTableStatistics", this.type, {
      rows: 9,
    });
  },
  methods: {
    onPage(obj) {
      this.$emit("getTableStatistics", this.type, obj);
    },
    redirect(id) {
      window.parent.location.href = `https://admin.lexmodo.com/products/${id}`;
    },
  },

  components: {
    DataTable,
    Column,
    EmpityTableStatistics,
  },
};
</script>

<style lang="scss">
.table-statistics {
  .p-datatable {
    border: 1px solid #e7e7e7;
    border-radius: 6px;
  }

  h4 {
    color: #202223;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 14px;
  }
  .p-paginator .p-paginator-current {
    margin-right: auto;
  }
  .p-datatable .p-paginator-bottom {
    border-bottom: none;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 14px;
  }
  .p-paginator-current {
    font-size: 14px;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    font-size: 14px;
  }
}
.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 14px;
}
</style>
