import axios from 'axios';
import { defineStore } from 'pinia';
import { useTableReviewsStore } from "@/service/TableProductsReview";
import { storeMessages } from "@/service/storeMessages";


export const Reviews = defineStore({
    id:"reviews",
 
    state: () => ({
        Api: "https://reviews-api.numinix.com/api/admin",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
        },
        loadingRequestStatistics: true,
        statistics: [
            {
                status:"all",
                total: 0
            },
            {
                status:"published",
                total: 0
            },
            {
                status:"pending",
                total: 0
            },
            {
                status:"hidden",
                total: 0
            }
        ],

        // Start Data to Add new review
        messages: [],
        sMessages: storeMessages(),
        productsItemsToAddNewReview: [],
        
    }),

    actions: {

        // Start get Statistics
        getStatistics(days) {
            console.log(this.headers)
            return axios.get(`${this.Api}/statistics/reviews?days=${days !== "All time" ? days : ''}`,{
                headers: this.headers
            }).then(data => {
                    this.loadingRequestStatistics = false;
                try {
                    data.data.forEach(child => {
                    
                        this.statistics.forEach((mainChild, index) => {
                         if(child.status === mainChild.status){
                            this.statistics[index] = child;
                         }
                        })
                     })
                }catch (error) {
                    console.error(error, 'changes in stricture data from api');
                }
            }).catch(err => {
                this.loadingRequestStatistics = false;
                try{
                    if(err.response.status === 401) {
                        // window.location.reload()
                    }
                }catch {
                    console.error(err, 'changes in stricture data from api');
                }
            })

        },
        

        // Start Create New Review
        createNewReview(objectSendData) {
            // console.log(this.productsItemsToAddNewReview)
            let objectSendDataOverride = {
                ...objectSendData,
                product_id: this.productsItemsToAddNewReview.length ? this.productsItemsToAddNewReview[0].product_id : "",
                product_name: this.productsItemsToAddNewReview.length ? this.productsItemsToAddNewReview[0].product_name  : "",
                product_image: this.productsItemsToAddNewReview.length ? this.productsItemsToAddNewReview[0].product_image  : "",
            }


            return axios.post(`${this.Api}/reviews`,objectSendDataOverride, {
                    headers: this.headers
            }).then(data => {
                if(data.data.review_added === "success") {
                    this.sMessages.messages= [];
                    setTimeout(() => {
                        this.sMessages.messages.push( { severity: "success", content: "Done Review Added" });
                    });
                    setTimeout(() => {
                        this.sMessages.messages = [];
                    }, 4000);
                    this.productsItemsToAddNewReview = [];

                    // Start to refresh reviews table and reviews statistics
                    const storeTable = useTableReviewsStore();
                    storeTable.onPage();
                    this.getStatistics()
                    return data.data.review_added
                }
            }).catch(err => {
                this.handleErrorToPrimeVue(err.response.data.errors)
            })
        },


        handleErrorToPrimeVue(errors) {
            this.sMessages.messages = [];
            setTimeout(() => {
                for (const key in errors) {
                    const element = errors[key];
                    this.sMessages.messages.push( { severity: "error", content: element[0],  life: 3000 });
                }
            })
            setTimeout(() => {
                this.sMessages.messages = [];
            }, 4000);
        },


        // Start search on products And Select products
        searchOnStoreProducts(value) {
            return axios.get(`https://reviews-api.numinix.com/api/admin/search?search=${value}`,{
                headers: this.headers
            }).then(data => {
                return data
            })
        },


        // start select product
        selectProducts(products) {
            this.productsItemsToAddNewReview = products;
        }
    }
})