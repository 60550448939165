<!-- This Copmponent to make new review  -->
<template>
  <pop-up-form
    button-name="Publish  review"
    class="add-new-review"
    title-popup="Add a product review"
    :show-popup="showPopups"
    :loading-button="loadingButton"
    @cansel-popup="cancelPopUpAddReview"
    @submitForm="createNewReview"
  >
    <template #body>
      <div class="row-body">
        <div class="row-tow">
          <div class="input">
            <label for="">Name</label>
            <InputText type="text" name="name" v-model="name" />
          </div>
          <div class="input">
            <label for="">Email</label>
            <InputText type="text" name="email" v-model="user_email" />
          </div>
        </div>

        <div class="row-tow">
          <div class="input">
            <label for="">Choose Product</label>
            <button-select
              :itemsSelects="store.productsItemsToAddNewReview"
              @open-select-productPopUp="openSelectProduct"
            ></button-select>
          </div>
          <div class="input">
            <label for="">Rating</label>
            <div class="ratting">
              <Rating v-model="product_score" :cancel="false" />
              <span>{{ product_score + ".0" }} </span>
            </div>
          </div>
        </div>

        <!-- Review Title -->
        <div class="input">
          <label for="">Review Title</label>
          <InputText type="text" name="review_title" v-model="review_title" />
        </div>

        <!-- Review Title -->
        <div class="input">
          <label for="">Body of Review</label>
          <Textarea type="text" v-model="review_body" />
        </div>
      </div>
    </template>
  </pop-up-form>
  <PopUpSelectProduct
    @select-products-submit="selectProductsSubmit"
    @cancel-select-product="cancelSelect"
    :show-popups-search="showChoseProduct"
  ></PopUpSelectProduct>
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import InputText from "primevue/inputtext";
// import FileUpload from "primevue/fileupload";
import ButtonSelect from "@/components/UI/ButtonSelect.vue";
import Rating from "primevue/rating";
import Textarea from "primevue/textarea";
import PopUpSelectProduct from "@/components/UI/Popups/PopUpSelectProduct.vue";
import { Reviews } from "@/service/Reviews";

export default {
  name: "AddNewReview",
  emits: ["cancelAddReview"],
  props: {
    showPopups: Boolean,
  },
  data() {
    return {
      showChoseProduct: false,
      store: Reviews(),
      review_body: "",
      product_id: "",
      product_score: 1,
      product_name: "",
      user_email: "",
      name: "",
      review_title: "",
      loadingButton: false,
    };
  },
  components: {
    PopUpForm,
    InputText,
    ButtonSelect,
    Rating,
    Textarea,
    PopUpSelectProduct,
    // FileUpload,
  },
  methods: {
    cancelPopUpAddReview() {
      this.$emit("cancelAddReview", false);
      this.product_score = 1;
    },
    myUploader(data) {
      console.log(data);
    },
    openSelectProduct() {
      // document.querySelector(".p-dialog").style.opacity = "0.7";
      this.showChoseProduct = true;
    },
    cancelSelect(value) {
      this.showChoseProduct = value;
    },
    selectProductsSubmit(data) {
      this.store.selectProducts(data);
      this.cancelSelect(false);
    },

    // Start Create New Review
    createNewReview() {
      this.loadingButton = true;
      this.store
        .createNewReview({
          review_body: this.review_body,
          product_id: this.product_id,
          product_score: this.product_score,
          product_name: this.product_name,
          user_email: this.user_email,
          name: this.name,
          review_title: this.review_title,
        })
        .then((data) => {
          if (data === "success") {
            this.cancelPopUpAddReview();
            this.resetForm();
          }
          this.loadingButton = false;
        })
        .catch(() => {
          this.loadingButton = false;
        });
    },

    // start reset form
    resetForm() {
      this.review_body = "";
      this.product_id = "";
      this.product_score = 1;
      this.user_email = "";
      this.name = "";
      this.user_email = "";
      this.review_title = "";
    },
  },
};
</script>

<style lang="scss">
.add-new-review {
  min-width: 800px;
}
.row-body {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.row-tow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.add-new-review {
  .p-dialog-footer {
    button {
      margin-top: 40px !important;
    }
  }
  input {
    background-color: #d8d8d8;
  }
  .pop-up-create h3 {
    font-size: 18px;
  }
  textarea {
    min-height: 150px;
  }
}

.ratting {
  padding-top: 10px;
  display: flex;
  gap: 15px;
  .p-rating {
    .p-rating-item.p-rating-item-active .p-rating-icon {
      color: #ffc453;
    }
  }
  .p-rating:not(.p-disabled):not(.p-readonly)
    .p-rating-item:hover
    .p-rating-icon {
    color: #f0a519;
  }
  .p-rating .p-rating-item .p-rating-icon {
    color: #ffc453;
  }
  .p-rating .p-rating-item.p-focus {
    border: none;
    box-shadow: none;
  }
  .p-rating .p-rating-item .p-rating-icon {
    font-size: 1.5rem;
  }
}
</style>
