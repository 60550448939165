<template>
  <section
    class="comment"
    :style="{
      padding:
        type === 'admin-answer' || type === 'user-answer'
          ? ' 10px 0 10px 50px'
          : ' 10px 0 10px 0',
    }"
  >
    <!-- Left section -->
    <div
      class="abbreviation-name"
      :style="{ background: type === 'user-question' ? '#07D09E' : '#004982' }"
    >
      JD
    </div>
    <div class="comment-in">
      <!-- start header comments -->
      <div class="header-comments">
        <div class="name-comments">
          <div class="details-comment">
            <h4>{{ nameComment }}</h4>
            <div class="details-comment-child">
              <span class="date">{{ getDateDiff(date * 1000) }}</span>
              <span>{{ status }}</span>
            </div>
          </div>
          <slot
            name="right-section-header"
            v-if="type === 'user-question'"
          ></slot>
        </div>
      </div>
      <!-- End Header comment-->

      <!-- Start Content Component-->
      <div class="body-comment">
        <div class="content">
          <div
            class="content-comment"
            :class="status === 'hidden' ? 'hidden-comment' : ''"
          >
            <!-- Start title content -->
            <div class="title-content" v-if="titleCommentStatus">
              <p style="font-weight: 700">{{ titleCommentContent }}</p>
            </div>
            <p>{{ contentComment }}</p>
          </div>
          <h5 v-show="status === 'hidden'" class="hidden-alert">
            This question is hidden from the product.
          </h5>
        </div>
        <div class="events-comments">
          <!-- start events comments -->
          <button
            class="button-light"
            :class="status === 'hidden' ? '' : 'hidden'"
            type="button"
            @click="hideAction"
            v-if="type === 'user-question'"
          >
            <div class="loader" v-if="loadingHidden"></div>
            <img src="@//assets/images/icons/hide.svg" v-if="!loadingHidden" />
          </button>
          <button
            class="button-light"
            type="button"
            @click="deleteAction"
            v-if="type === 'user-question'"
          >
            <div class="loader" v-if="loadingDelete"></div>
            <img
              src="@/assets/images/icons/delete-icone.svg"
              v-if="!loadingDelete"
            />
          </button>

          <button
            class="button-light"
            type="button"
            v-if="status !== 'approved' && status !== 'answered'"
            @click="approveAction"
          >
            <div class="loader" v-if="loadingPublished"></div>
            <img
              src="@/assets/images/icons/approve.svg"
              v-if="!loadingPublished"
            />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { watch } from "vue";
import { ref } from "vue";

export default {
  name: "CommentComponentUi",
  props: {
    nameComment: String,
    date: Number,
    contentComment: String,
    quationId: String,
    type: String,
    statusHiddenComment: Boolean,
    product_name: String,
    status: {
      default: "pending",
    },
    loadingDelete: {
      type: Boolean,
      default: false,
    },
    loadingHidden: {
      type: Boolean,
      default: false,
    },
    loadingPublished: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    titleCommentStatus: {
      type: Boolean,
      default: false,
    },
    titleCommentContent: {
      type: String,
    },
  },
  setup(props) {
    const statusHiddenCommentVar = ref(false);
    watch(
      () => props.statusHiddenComment,
      (newVal, oldVal) => {
        console.log(`Prop changed from ${oldVal} to ${newVal}`);
        statusHiddenCommentVar.value = newVal;
      }
    );
    return {
      statusHiddenCommentVar,
    };
  },
  methods: {
    hideAction() {
      let obj = {
        index: this.index,
        id: this.quationId,
      };
      this.$emit("actionHide", obj);
      this.statusHiddenCommentVar === true
        ? (this.statusHiddenCommentVar = false)
        : (this.statusHiddenCommentVar = true);
    },
    deleteAction() {
      let obj = {
        index: this.index,
        id: this.quationId,
      };
      this.$emit("deleteAction", obj);
    },
    approveAction() {
      let obj = {
        index: this.index,
        id: this.quationId,
      };
      this.$emit("approveAction", obj);
    },

    getDateDiff(date) {
      const oneMinute = 60 * 1000; // Number of milliseconds in a minute
      const oneHour = 60 * oneMinute; // Number of milliseconds in an hour
      const oneDay = 24 * oneHour; // Number of milliseconds in a day
      const currentDate = new Date(); // Current date and time
      const dateToCompare = new Date(date); // Date to compare
      const diffInMilliseconds = currentDate - dateToCompare; // Difference in milliseconds

      if (diffInMilliseconds < oneHour) {
        const diffInMinutes = Math.round(diffInMilliseconds / oneMinute);
        return `${diffInMinutes} minute(s) ago`;
      } else if (diffInMilliseconds < oneDay) {
        const diffInHours = Math.floor(diffInMilliseconds / oneHour);
        return `${diffInHours} hour(s) ago`;
      } else {
        const diffInDays = Math.floor(diffInMilliseconds / oneDay);
        return `${diffInDays} day(s) ago`;
      }
    },
  },
};
</script>

<style lang="scss">
.comment {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 16fr;
  padding: 15px 0;
  // Start header comments
  .comment-in {
    display: flex;
    flex-direction: column;
    gap: 11px;

    .name-comments {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: space-between;

      .details-comment {
        display: flex;
        flex-direction: column;
        width: 80%;

        h4 {
          margin: 0;
          font-weight: 600;
          color: #202223;
          font-size: 16px;
          text-transform: capitalize;
        }
        .details-comment-child {
          display: flex;
          align-items: center;
          gap: 6px;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #6d6d6d;
          }
        }
      }
      .date {
        font-size: 14px;
        font-weight: 400;
        color: #6d6d6d;
      }
    }
  }
  // start body-commen
  .body-comment {
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 15px;
    .hidden-alert {
      font-size: 10px;
      margin-top: 10px;
      font-weight: 400;
    }
    .content-comment {
      padding: 10px 13px;
      background-color: #f2f5fa;
      border-radius: 8px;

      &.hidden-comment {
        background-color: #e7e7e7;
        opacity: 0.6;
      }
      p {
        font-size: 14px;
        color: #202223;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .events-comments {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      justify-content: flex-end;
      .button-light {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 45px;
        height: 45px;
        transition: 0.3s background-color;
        &:hover {
          background-color: #6d6d6d3f;
        }
      }

      //start edit on loader
      .loader {
        width: 17px;
        height: 17px;
        border: 3px solid #6d6d6d;
        border-bottom-color: transparent;
      }
    }
  }

  .abbreviation-name {
    text-transform: uppercase;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: #07d09e;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    display: flex;
  }
}
.button-light {
  &.hidden {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 60%;
      width: 3px;
      background-color: #6d6d6d;
      transform: rotate(45deg);
      border-radius: 6px;
    }
  }
}
</style>
