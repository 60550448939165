import axios from 'axios';
import { defineStore } from 'pinia';


export const useTableReviewsStore = defineStore({
    id: 'useTableReviewsStore',
    state: () => ({
      Api: "https://reviews-api.numinix.com/api/admin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("auth_reviews")}`
      },
      itemsReviews:[],
      total: 0,
      rows:10,
      selectedItems: [],
      infoTableObject: {},
      loadingTableReviews: false

    }),


    actions: {
        onPage(object){
          this.infoObject = object;
          this.requestGet()
        },

        // Get Request to get reviews 
        requestGet() {
            this.loadingTableReviews = true;
            axios.get(`${this.Api}/products/reviews`, {
              headers: this.headers,
              params: {
                dt_params: JSON.stringify({
                  ...this.infoObject,
                  rows: this.rows
                })
              },
            }).then(data => {
              this.loadingTableReviews = false;
              this.itemsReviews = data.data.data;
              this.total = data.data.total;
              console.log(data)
              // this.fixShownData(data.data.data)
              this.addLoading()
            }).catch(err => {
              console.log(err)
            })
        },


        // This method make fixShownData 
        fixShownData(oldData) {

          let newData = []

          oldData.forEach((valueOld) => {
            
            if(newData.length > 0){

                if(!newData.some(obj => obj.product_id === valueOld.product_id)) {

                  newData.push(this.makeNewProductObject(valueOld))

                }else {

                  // New array has this item and has update and append another status to product
                  const newObject = newData.find(obj => obj.product_id === valueOld.product_id),
                        index =  newData.findIndex(obj => obj.product_id === valueOld.product_id);
                  if(!Object.prototype.hasOwnProperty.call(newObject, valueOld.status)) {
                    let updateObject = {
                      ...newObject,
                    }
                    updateObject[`${valueOld['status']}`] = valueOld['count'];
                    updateObject[`all`] += valueOld['count'];
                    newData[index] = updateObject;
                  }
                }
                 
            }else
              // if this array has not any data
               newData.push(this.makeNewProductObject(valueOld))

          })
          
          this.itemsReviews = newData;
          this.addLoading()
          
        },

        //Make new Object to product
        makeNewProductObject(valueOldObject) {
          let newObjectProduct = {
            product_id: valueOldObject.product_id,
            product_name: valueOldObject.product_name,
            all: valueOldObject.count,
            product_image: valueOldObject.product_image,
            rating: valueOldObject.reviews_product_rating
          }
          
          newObjectProduct[`${valueOldObject['status']}`] = valueOldObject['count']
          return newObjectProduct
         
        },

        addLoading() {
          this.itemsReviews =
            this.itemsReviews.map((item) => {
              return {
                ...item,
                loading: false,
              };
            });
        },
        

      }

  });