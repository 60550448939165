<template>
  <div class="container">
    <div class="body-content-this body-boxs">
      <div class="row-state">
        <BoxOfStatistic
          :loading-request="store.loadingRequestStatistics"
          :box-name="'All reviews'"
          :box-counter="store.statistics[0].total"
          :box-color="'green'"
        >
          <template #icone>
            <img
              src="@/assets/images/icons/boxs_icones/star.svg"
              alt=""
              srcset=""
            />
          </template>
        </BoxOfStatistic>
        <BoxOfStatistic
          :loading-request="store.loadingRequestStatistics"
          :box-name="'Published reviews'"
          :box-counter="store.statistics[1].total"
          :box-color="'yallow'"
        >
          <template #icone>
            <img
              src="@/assets/images/icons//boxs_icones/published.svg"
              alt=""
              srcset=""
            />
          </template>
        </BoxOfStatistic>

        <BoxOfStatistic
          :loading-request="store.loadingRequestStatistics"
          :box-name="'Pending reviews'"
          :box-counter="store.statistics[2].total"
          :box-color="'blue'"
        >
          <template #icone>
            <img
              src="@/assets/images/icons//boxs_icones/loading.svg"
              alt=""
              srcset=""
            />
          </template>
        </BoxOfStatistic>
        <BoxOfStatistic
          :loading-request="store.loadingRequestStatistics"
          :box-name="'Hidden reviews'"
          :box-counter="store.statistics[3].total"
          :box-color="'gray'"
        >
          <template #icone>
            <img
              src="@/assets/images/icons//boxs_icones/hideicone.svg"
              alt=""
              srcset=""
            />
          </template>
        </BoxOfStatistic>
      </div>
      <date-drop-down
        :statusLoadingProp="statusLoadingProp"
        @changeDateDashbord="changeDateDashbord"
      ></date-drop-down>
    </div>
  </div>
</template>

<script>
import BoxOfStatistic from "@/components/UI/Dashboard/BoxOfStatistic.vue";
import DateDropDown from "@/components/UI/Dashboard/DateDropDown.vue";
import { ref } from "vue";
import { Reviews } from "@/service/Reviews";

export default {
  name: "StatisticsReviews",

  setup() {
    const statusLoadingProp = ref(false),
      store = Reviews();
    return {
      statusLoadingProp,
      store,
    };
  },
  mounted() {
    this.store.getStatistics("All time");
  },
  components: {
    BoxOfStatistic,
    DateDropDown,
  },

  methods: {
    async changeDateDashbord(value) {
      this.statusLoadingProp = true;
      this.store.getStatistics(value).then(() => {
        this.statusLoadingProp = false;
      });
    },
  },
};
</script>

<style lang="scss">
.row-state {
  display: flex;
  gap: 20px;
  flex-flow: wrap;
}
.body-boxs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
