<template>
  <statistics-reviews> </statistics-reviews>
  <TableProductsReview></TableProductsReview>
  <AlertComponent :messages="store.messages"></AlertComponent>
  <pop-up-reviews
    :show-popup="storeReviewEvents.showPopUp"
    @canselPopup="storeReviewEvents.hidePopUp()"
  ></pop-up-reviews>
</template>

<script>
import StatisticsReviews from "@/views/Reviews/Components/StatisticsReviews.vue";
import TableProductsReview from "@/views/Reviews/Components/TableProductsReview.vue";
import AlertComponent from "@/components/UI/AlertComponent.vue";
import PopUpReviews from "./Components/PopUpReviews.vue";
import { Reviews } from "@/service/Reviews";
import { useReviewsEvents } from "@/service/ReviewsEvenetsPopup";

export default {
  name: "ReviewsDashboard",
  emits: ["routeName", "mainButton"],

  setup() {
    const store = new Reviews(),
      storeReviewEvents = useReviewsEvents();
    return {
      store,
      storeReviewEvents,
    };
  },

  mounted() {
    this.$emit("routeName", "Reviews");
    this.$emit("mainButton", "Review");
  },

  components: {
    StatisticsReviews,
    TableProductsReview,
    AlertComponent,
    PopUpReviews,
  },
};
</script>
