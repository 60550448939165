<template>
  <div class="table-reviews container">
    <div class="body-content-this">
      <!-- H E A D E R   T A B L E -->
      <div class="search-bar" style="position: relative">
        <div class="input-container">
          <i class="fa fa-search icon">
            <img
              src="@/assets/images/icons/search.svg"
              alt=""
              srcset=""
              style="opacity: 0.7"
            />
          </i>
          <input
            type="text"
            placeholder="Search"
            v-model="filters['product_name'].value"
            @input="makeFilter"
          />
        </div>
        <div class="right-section">
          <div class="question-total">
            <h5>{{ storeReviewTable.total }} items</h5>
          </div>
          <DropDownSorting
            @change-sorting="changeSorting"
            :data-sorting="dataSortingOptions"
          ></DropDownSorting>
          <!-- <div class="pagination">
            <div class="button-row d-flex" style="gap: 5px">
              <button class="button-light">
                <img src="@/assets/images/icons/left-button.svg" />
              </button>
              <button class="button-light">
                <img src="@/assets/images/icons/right-button.svg" />
              </button>
            </div>
          </div> -->
        </div>
      </div>

      <DataTable
        class="request-table"
        :totalRecords="storeReviewTable.total"
        @page="storeReviewTable.onPage($event)"
        @sort="storeReviewTable.onPage($event)"
        :loading="storeReviewTable.loadingTableReviews"
        :filters="filters"
        :globalFilterFields="['product_name']"
        @filter="storeReviewTable.onPage($event)"
        v-model:selection="storeReviewTable.selectedItems"
        :value="storeReviewTable.itemsReviews"
        dataKey="id"
        :lazy="true"
        :paginator="true"
        :rows="storeReviewTable.rows"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        responsiveLayout="scroll"
        ref="dataTable"
      >
        <template #loading>
          <div class="loader table-loader"></div>
        </template>

        <!-- S T A R T  B O D Y  C O N T E N T -->
        <Column
          :field="data.field"
          :header="data.header"
          :sortable="true"
          v-for="data in tableProductsReviewKeys"
          v-bind:key="data"
        >
          <template v-if="data.header === 'Product'" #body="{ data }">
            <a
              @click="redirect(data.product_id)"
              style="cursor: pointer"
              class="open-link product-link-img"
            >
              <img :src="data.product_image" alt="" />
              <span>{{ data.product_name }} </span>
            </a>
          </template>
          <template v-else-if="data.header === 'All'" #body="{ data }">
            <span>{{
              data.all_reviews_count ? data.all_reviews_count : 0
            }}</span>
          </template>
          <template
            v-else-if="data.header === 'approved_reviews_count'"
            #body="{ data }"
          >
            <span>{{ data.published ? data.published : 0 }}</span>
          </template>
          <template v-else-if="data.header === 'Hidden'" #body="{ data }">
            <span>{{
              data.hidden_reviews_count ? data.hidden_reviews_count : 0
            }}</span>
          </template>
          <template v-else-if="data.header === 'Rating'" #body="{ data }">
            <a href="#" class="rating">
              <img src="@/assets/images/icons/star.svg" alt="" />
              <span>{{ data.reviews_product_rating }}</span>
            </a>
          </template>
          <template v-else-if="data.field === 'actions'" #body="{ data }">
            <button
              @click="storeReviewsEvents.ShowReview(data.product_id)"
              style="
                min-width: 112px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 41px;
              "
            >
              <div class="loader mini-loader" v-if="data.loading"></div>
              <span v-if="!data.loading">Show Reviews</span>
            </button>
          </template>
        </Column>

        <template #empty>
          <empty-big-table message="No reviews found"></empty-big-table>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
// Start Ui Components
import EmptyBigTable from "@/components/UI/EmptyBigTable.vue";
import { useTableReviewsStore } from "@/service/TableProductsReview";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import tableProductsReviewKeys from "@/views/Reviews/Components/tableProductsReviewKeys.json";
import DropDownSorting from "@/components/TableComponent/DropDownSorting.vue";
import { useReviewsEvents } from "@/service/ReviewsEvenetsPopup";
import { ref } from "vue";

export default {
  name: "TableProductsReview",
  setup() {
    const storeReviewTable = useTableReviewsStore(),
      storeReviewsEvents = useReviewsEvents(),
      filters = ref({
        product_name: { value: "" },
      }),
      dataSortingOptions = [{ name: "Product Name", value: "product_name" }];

    return {
      tableProductsReviewKeys,
      storeReviewTable,
      filters,
      storeReviewsEvents,
      dataSortingOptions,
    };
  },
  components: {
    EmptyBigTable,
    Column,
    DataTable,
    DropDownSorting,
  },
  mounted() {
    this.storeReviewTable.onPage();
  },

  methods: {
    changeSorting(value) {
      // this.storeReviewTable.sortField = value;
      this.storeReviewTable.onPage({
        sortField: value,
        rows: 10,
      });
    },

    // START SEARCH ON PRODUCTS
    makeFilter() {
      this.storeReviewTable.onPage({
        filters: this.filters,
      });
    },

    redirect(id) {
      window.parent.location.href = `https://admin.lexmodo.com/products/${id}`;
    },
  },
};
</script>

<style lang="scss">
.table-reviews {
  margin-top: 20px;
  .body-content-this {
    border-radius: 8px;
  }
}
.product-link-img {
  gap: 15px;
  img {
    width: 40px;
    height: 40px;
    object-fit: fill;
  }
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
}
.product-link-img-img span {
  width: 170px;
}
</style>
