<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    rel="stylesheet"
  />
  <div class="loading-section loading-app" v-if="!statusApp">
    <div class="loader"></div>
  </div>

  <section v-if="statusApp">
    <upper-bar :app-name="'QA'"></upper-bar>
    <!-- Start Header plugin -->
    <header-plugin
      :component-name="title"
      :buttonName="buttonName"
    ></header-plugin>
    <site-bar></site-bar>

    <!-- Start content plugin -->
    <router-view
      @route-name="getTitle"
      @main-button="getButtonName"
      ref="child"
    ></router-view>
  </section>
</template>

<script>
import HeaderPlugin from "./components/HeaderPlugin.vue";
import upperBar from "./components/upperBar.vue";
import SiteBar from "./components/SiteBar.vue";
import { authLogin } from "./service/authLogin";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "App",
  setup() {
    // expose to template and other options API hooks
    const title = ref("");
    const buttonName = ref(""),
      statusApp = ref(false);
    const auth = new authLogin();
    const router = useRouter();

    auth.startApp()?.then((data) => {
      if (!data.meta.redirect) {
        statusApp.value = true;
        router.push("/");
      }
    });

    return { title, buttonName, statusApp };
  },
  components: {
    HeaderPlugin,
    upperBar,
    SiteBar,
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
    getButtonName(value) {
      this.buttonName = value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/global.scss";

// start
.body-content {
  background-color: #fff;
  min-height: 65vh;
  padding: 25px 18px;
  border: 1px solid #edeeef;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 10px 13px -2px rgb(106 115 121 / 5%);
}
</style>
