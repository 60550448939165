<template>
  <section class="dashboard">
    <div class="container">
      <div class="body-content">
        <div class="dashboard-top">
          <div class="welcome">
            <div class="row">
              <div class="title-left">
                <h3>
                  Good Afternoon<span v-if="clientName"
                    >, {{ clientName }}</span
                  >
                </h3>
                <p>It's so good to see you here</p>
              </div>
              <DateDropDown
                valueDate="All time"
                :statusLoadingProp="store.filterDateLoading"
                @change-date-dashbord="changeDateToDashboard"
              ></DateDropDown>
            </div>
          </div>

          <div class="row-stat">
            <BoxOfStatistic
              :box-name="'Pending Questionss'"
              :box-counter="store.statistics[0].total"
              :loading-request="store.loadingRequestStatistics"
              :box-color="'blue'"
            >
              <template #icone>
                <img src="@/assets/images/icons/quation.svg" alt="" srcset="" />
              </template>
            </BoxOfStatistic>
            <BoxOfStatistic
              :box-name="'Answered'"
              :box-counter="store.statistics[1].total"
              :loading-request="store.loadingRequestStatistics"
              :box-color="'yallow'"
            >
              <template #icone>
                <img
                  src="@/assets/images/icons/answered.svg"
                  alt=""
                  srcset=""
                />
              </template>
            </BoxOfStatistic>
            <BoxOfStatistic
              :box-name="'Approved'"
              :box-counter="store.statistics[2].total"
              :loading-request="store.loadingRequestStatistics"
              :box-color="'green'"
            ></BoxOfStatistic>
            <BoxOfStatistic
              :box-name="'Hidden'"
              :box-counter="store.statistics[3].total"
              :loading-request="store.loadingRequestStatistics"
              :box-color="'orange'"
            ></BoxOfStatistic>
            <BoxOfStatistic
              :box-name="'Deleted'"
              :box-counter="store.statistics[4].total"
              :loading-request="store.loadingRequestStatistics"
              :box-color="'red'"
            >
              <template #icone>
                <img src="@/assets/images/icons/deleted.svg" alt="" srcset="" />
              </template>
            </BoxOfStatistic>
          </div>
        </div>

        <div class="dashboard-bottom statisticstable">
          <statistics-table
            title-table="Top Reviewed"
            type="topReviewed"
            headC="Reviews"
            message-error="No Reviewed products found"
            :dataItems="dataTopReviewed"
            @get-table-statistics="getDataStatistics"
            :loading-table="loadingReviewed"
          ></statistics-table>
          <statistics-table
            type="topAsked"
            headC="Counts"
            :dataItems="dataTopAsked"
            @get-table-statistics="getDataStatistics"
            title-table="Top Asked "
            message-error="No Asked products found"
            :loading-table="loadingTopAsked"
          ></statistics-table>
          <statistics-table
            type="lowestAsked"
            headC="Counts"
            :dataItems="dataLowestAsked"
            @get-table-statistics="getDataStatistics"
            title-table="Lowest Rated"
            message-error="No Rated products found"
            :loading-table="loadingLowestAsked"
          >
          </statistics-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxOfStatistic from "@/components/UI/Dashboard/BoxOfStatistic.vue";
import StatisticsTable from "@/components/UI/Dashboard/StatisticsTable.vue";
import DateDropDown from "@/components/UI/Dashboard/DateDropDown.vue";
import { DashboardQuestions } from "@/service/QuestionStore/DashboardQuestions.js";

export default {
  name: "DashBoard",
  emits: ["routeName", "mainButton"],
  components: {
    BoxOfStatistic,
    StatisticsTable,
    DateDropDown,
  },
  data() {
    return {
      store: DashboardQuestions(),
      dataLowestAsked: {
        total: 0,
        data: [],
      },
      dataTopReviewed: {
        total: 0,
        data: [],
      },
      dataTopAsked: {
        total: 0,
        data: [],
      },
      loadingReviewed: false,
      loadingTopAsked: false,
      loadingLowestAsked: false,
      clientName: "",
    };
  },
  mounted() {
    this.$emit("routeName", "Dashboard");
    this.$emit("mainButton", "Question");

    //Start To make requests
    this.store.getStatistics(" ");
  },
  methods: {
    changeDateToDashboard(date) {
      // In here we make request to dashbord date
      this.store.filterDateLoading = true;
      this.store.getStatistics(date);
    },

    // Start get Data  Statistics
    getDataStatistics(typeTable, obj) {
      switch (typeTable) {
        case "topReviewed":
          this.loadingReviewed = true;
          break;
        case "lowestAsked":
          this.loadingLowestAsked = true;
          break;
        case "topAsked":
          this.loadingTopAsked = true;
          break;
      }
      this.store
        .getStatisticsTables(typeTable, obj)
        .then((data) => {
          switch (typeTable) {
            case "topReviewed":
              this.dataTopReviewed = data;
              this.loadingReviewed = false;
              break;
            case "lowestAsked":
              this.dataLowestAsked = data;
              this.loadingLowestAsked = false;
              break;
            case "topAsked":
              this.dataTopAsked = data;
              this.loadingTopAsked = false;
              break;
          }
        })
        .catch((err) => {
          this.loadingReviewed = false;
          this.loadingTopAsked = false;
          this.loadingLowestAsked = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  .row-stat {
    display: flex;
    gap: 20px;
    flex-flow: wrap;
  }
  .welcome {
    margin-bottom: 40px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    p {
      color: #6d6d6d;
      font-size: 14px;
    }
  }
}
.dashboard-top {
  padding-bottom: 40px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 40px;
}

// table section statisticstable
.statisticstable {
  display: grid;
  gap: 13px;
  grid-template-columns: 1fr 1fr 1fr;
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 10px 15px;
    font-size: 12px;
  }
  .p-component {
    padding: 3px 5px;
  }
}
@media (max-width: 1200px) {
  .statisticstable {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 850px) {
  .statisticstable {
    grid-template-columns: 1fr;
  }
}

// start css to welcome section
.welcome {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
