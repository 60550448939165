<template>
  <!-- Start Answer To Quation -->
  <pop-up-form
    title-popup="Reviews for this product"
    button-name="Review"
    :showSubmitButton="false"
    :canselButtonName="false"
    :showPopup="showPopup"
    class="popup-answer"
    @canselPopup="canselPopup"
  >
    <template #body>
      <!-- This is a question -->
      <div class="quation-answer">
        <comment-component-ui
          v-for="(item, index) in useReviews.reviewsData"
          v-bind:key="item.id"
          :type="'user-question'"
          :name-comment="item.name"
          :date="item.created_at"
          :content-comment="item.review_body"
          :title-comment-status="true"
          :title-comment-content="item.review_title"
          :quation-id="item.review_id"
          :index="index"
          :statusHiddenComment="true"
          :product-name="item.product_name"
          :status="item.status"
          @actionHide="hideAction"
          @deleteAction="deleteAction"
          @approveAction="approveAction"
          :loading-delete="item.loadingDelete"
          :loading-hidden="item.loadingHidden"
          :loading-published="item.loadingPublished"
        >
          <template #right-section-header>
            <div class="stars">
              <img src="@/assets/images/icons/star.svg" alt="" srcset="" />
              <span class="rate"> {{ item.product_score }}.0 </span>
            </div>
          </template>
        </comment-component-ui>
      </div>
      <!-- Start make show more -->
      <div
        class="show-more"
        v-if="
          useReviews.totalReviews > useReviews.rows &&
          useReviews.totalReviews !== useReviews.reviewsData.length
        "
        @click="useReviews.showMoreReviews(useReviews.totalReviews - 3)"
      >
        <h5>
          Show {{ useReviews.totalReviews - 3 }} more Reviews
          <svg
            width="12"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#575959"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </h5>
      </div>
    </template>
  </pop-up-form>
</template>

<script>
import PopUpForm from "@/components/Forms/PopUpForm.vue";
import CommentComponentUi from "@/components/UI/CommentComponentUi.vue";
import { useReviewsEvents } from "@/service/ReviewsEvenetsPopup";

export default {
  name: "PopUpReviews",
  data() {
    return {
      useReviews: useReviewsEvents(),
      dataReviews: [],
    };
  },
  components: {
    PopUpForm,
    CommentComponentUi,
  },

  props: {
    showPopup: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    hideAction(obj) {
      this.useReviews.actionsOnReview(obj.id, "hide");
      this.useReviews.reviewsData[obj.index].loadingHidden = true;
    },
    deleteAction(obj) {
      this.useReviews.actionsOnReview(obj.id, "delete");
      this.useReviews.reviewsData[obj.index].loadingDelete = true;
    },
    approveAction(obj) {
      this.useReviews.actionsOnReview(obj.id, "approve");
      this.useReviews.reviewsData[obj.index].loadingPublished = true;
    },
    canselPopup() {
      this.$emit("canselPopup");
    },
  },
};
</script>

<style lang="scss">
.popup-answer {
  width: 800px;
}
.product-link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #1575bf;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    width: fit-content;
  }
}

.answer-comment {
  textarea {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    margin-top: 5px;
    min-height: 135px;
    padding: 10px 11px;
    font-size: 14px;
    color: #202223;
    outline: none;
  }
}

.show-more {
  display: flex;
  justify-content: flex-end;
  h5 {
    margin-top: 8px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.stars {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 6px;
  span {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
