<template>
  <!-- start QA -->
  <div class="header-plugin">
    <h2
      :style="{
        color: storeD.main_color,
      }"
    >
      Product Reviews
    </h2>
  </div>
  <div class="statistics">
    <div class="head-statistics">
      <h3
        :style="{
          color: storeD.main_color,
        }"
      >
        <span>{{ store.average }}/</span>5
      </h3>
      <p>Based on 17 review(s)</p>
      <ul class="stars">
        <div
          class="star-hidden"
          :style="{ width: 100 - store.average * 20 + '%' }"
        ></div>
        <li><StarDiv></StarDiv></li>
        <li><StarDiv></StarDiv></li>
        <li><StarDiv></StarDiv></li>
        <li><StarDiv></StarDiv></li>
        <li><StarDiv></StarDiv></li>
      </ul>
    </div>

    <div class="body-statistics">
      <!-- more-informations -->
      <ul class="more-informations">
        <li class="bar" v-for="count in store.counts" v-bind:key="count">
          <div class="length-stars">
            <span>{{ count.product_score }}</span>
            <StarDiv></StarDiv>
          </div>
          <div class="content-bar">
            <div
              class="value"
              :style="{
                width: count.product_score_width,
                backgroundColor: storeD.starColor,
              }"
            ></div>
          </div>
          <div class="length-reviews">({{ count.count }})</div>
        </li>
        <!-- <li class="bar">
            <div class="length-stars">
              <span>4</span>
              <img src="@/assets/images/star.svg" alt="" />
            </div>
            <div class="content-bar">
              <div class="value" style="width: 60%"></div>
            </div>
  
            <div class="length-reviews">(12)</div>
          </li>
          <li class="bar">
            <div class="length-stars">
              <span>3</span>
              <img src="@/assets/images/star.svg" alt="" />
            </div>
            <div class="content-bar">
              <div class="value" style="width: 20%"></div>
            </div>
  
            <div class="length-reviews">(12)</div>
          </li>
          <li class="bar">
            <div class="length-stars">
              <span>2</span>
              <img src="@/assets/images/star.svg" alt="" />
            </div>
            <div class="content-bar">
              <div class="value" style="width: 25%"></div>
            </div>
  
            <div class="length-reviews">(12)</div>
          </li>
          <li class="bar">
            <div class="length-stars">
              <span>1</span>
              <img src="@/assets/images/star.svg" alt="" />
            </div>
            <div class="content-bar">
              <div class="value"></div>
            </div>
  
            <div class="length-reviews">(12)</div>
          </li> -->
      </ul>
      <!-- end more-informations -->
    </div>
  </div>
</template>

<script>
import StarDiv from "@/components/UI/images/StarDiv.vue";
import { storeDetails } from "@/service/StoreDetails";

export default {
  name: "header-product-reviews",
  components: {
    StarDiv,
  },
  data() {
    return {
      storeD: storeDetails(),
      store: {
        average: 5,
        counts: [
          {
            count: 1,
            product_score: 1,
            product_score_width: "25%",
          },
          {
            count: 2,
            product_score: 2,
          },
          {
            count: 3,
            product_score: 8,
          },
          {
            count: 0,
            product_score: 4,
          },
          {
            count: 0,
            product_score: 5,
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    getStatistics() {},
  },
};
</script>

<style lang="scss">
//statistics
.statistics {
  padding: 50px 0;
}
.header-plugin {
  display: flex;
  justify-content: center;
  background: #f2f5fa;
  padding: 20px 0;
  h2 {
    padding: 0;
    font-size: 20px;
    margin: 0;
    font-weight: 600;
  }
}

// start body-statistics
.head-statistics {
  text-align: center;

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    span {
      font-size: 28px;
      font-weight: 700;
    }
  }
  .stars {
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    gap: 10px;
    list-style: none;
    position: relative;
    .star-hidden {
      position: absolute;
      right: 0;
      height: 100%;
      width: 10%;
      background-color: #ffffffa8;
    }
  }
}

// start body-statistics
.body-statistics {
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: grid;
      grid-template-columns: 1fr 520px 1fr;
      align-items: center;
      gap: 10px;

      //content-bar
      .content-bar {
        height: 12px;
        background-color: #f2f5fa;
        border-radius: 4px;
        opacity: 1;
        position: relative;
        border-radius: 5px;
        .value {
          position: absolute;
          height: 100%;
          background-color: #ffc453;
          border-radius: 5px;
        }
      }

      //.length-stars
      .length-stars {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
</style>
