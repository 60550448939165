<template>
  <div class="alert">
    <Message
      v-for="msg of messages"
      :severity="msg.severity"
      :key="msg.content"
      >{{ msg.content }}</Message
    >
  </div>
</template>

<script>
import Message from "primevue/message";

export default {
  name: "alert-component",

  components: {
    Message,
  },
  props: {
    messages: {
      typeof: Array,
      default: [],
    },
  },
  computed: {
    // alertClasses() {
    //   if (this.typeAlert === "success") {
    //     return "alert alert-success";
    //   } else if (this.typeAlert === "error") {
    //     return "alert alert-error";
    //   } else {
    //     return "alert";
    //   }
    // },
    // iconFill() {
    //   if (this.typeAlert === "success") {
    //     return "#07D09E";
    //   } else if (this.typeAlert === "error") {
    //     return "#e37474";
    //   } else {
    //     return "#e37474";
    //   }
    // },
  },
};
</script>

<style scoped>
.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
}
</style>
