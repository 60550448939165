<style>
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px;
}
</style>
<template>
  <div class="container">
    <filters-labels></filters-labels>
    <div class="body-content">
      <!-- start table -->
      <div id="table-customers">
        <!-- START Search BAR -->
        <div class="search-bar" style="position: relative">
          <div class="input-container">
            <i class="fa fa-search icon">
              <img
                src="@/assets/images/icons/search.svg"
                alt=""
                srcset=""
                style="opacity: 0.7"
              />
            </i>
            <input
              type="text"
              placeholder="Search by questions"
              v-model="filters.content.value"
              v-if="searchBy === 'Questions'"
              @input="makeFilter"
            />
            <input
              type="text"
              placeholder="Search by products"
              v-model="filters.product.product_name.value"
              v-if="searchBy === 'Products'"
              @input="makeFilter"
            />
          </div>
          <div class="right-section">
            <div class="question-total">
              <h5>{{ total }} items</h5>
            </div>
            <Dropdown
              v-model="searchBy"
              :options="searchTerms"
              optionLabel="name"
              optionValue="value"
              style="
                width: 150px;
                border: 1px solid #d8d8d8;
                border-radius: 8px;
              "
            ></Dropdown>
            <DropDownSorting
              @change-sorting="changeSorting"
              :data-sorting="dataSortingOptions"
            ></DropDownSorting>
            <div class="pagination">
              <div class="button-row d-flex" style="gap: 5px">
                <button class="button-light">
                  <img src="@/assets/images/icons/left-button.svg" />
                </button>
                <button class="button-light">
                  <img src="@/assets/images/icons/right-button.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- END Search BAR -->
        <questionTable></questionTable>

        <div
          class="deleteBar"
          ref="deleteBar"
          v-if="selectedProducts.length > 0"
        >
          <ConfirmDialog style="border-radius: 12px"></ConfirmDialog>
          <button @click="deleteSelected">Delete Selected</button>
          <p>{{ selectedProducts?.length }} selected</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import FiltersLabels from "@/components/QuestionsAnswers/FiltersLabels.vue";
import Dropdown from "primevue/dropdown";
import ConfirmDialog from "primevue/confirmdialog";
// Table main conponents
import DropDownSorting from "@/components/TableComponent/DropDownSorting.vue";

// Start Answer  Poup
import { useTableStore } from "@/service/QuestionStore/QuationAndAnswerTable";
import { ref } from "vue";

import questionTable from "./components/questionTable.vue";

export default {
  name: "QuestionsAnswers",
  emits: ["routeName", "mainButton"],

  setup() {
    const store = useTableStore();
    const filters = ref({
      content: { value: "", matchMode: "contains" },
      product: { product_name: { value: "", matchMode: "contains" } },
    });
    return {
      store,
      filters,
    };
  },

  data() {
    return {
      searchBy: "Questions",
      action: "Approve",
      action_values: [
        { name: "Approve", value: "Approve" },
        { name: "Hide", value: "Hide" },
        { name: "Delete", value: "delete" },
      ],
      searchTerms: [
        { name: "Questions", value: "Questions" },
        { name: "Products", value: "Products" },
      ],
      dataSortingOptions: [
        { name: "Questions", value: "content" },
        { name: "Status", value: "status" },
        { name: "Email", value: "email" },
        { name: "Name", value: "name" },
        { name: "Status", value: "status" },
      ],

      total: 5,
      error: false,
      loading: true,

      selectedProducts: [],
    };
  },
  mounted() {
    this.$emit("routeName", "Questions/ Answers");
    this.$emit("mainButton", "Question");
  },
  components: {
    FiltersLabels,
    Dropdown,
    ConfirmDialog,
    DropDownSorting,
    questionTable,
  },

  // START methods
  methods: {
    filterMethod(event) {
      // when make filter
      console.log(event);
    },
    changeSorting(value) {
      this.store.sortField = value;
      this.store.onPage({
        sortField: value,
        rows: 10,
      });
    },
    makeFilter() {
      this.store.sortField = "";
      this.store.onPage({
        filters: this.filters,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Table styles */
.body-content {
  padding: 0;
}
#table-customers {
  position: relative;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  overflow: hidden !important;
}

.p-component {
  font-family: "Plus Jakarta Sans", sans-serif;
}
</style>
