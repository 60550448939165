import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import router from "./router/index";
import LitepieDatepicker from 'litepie-datepicker';
import "./assets/tailwimd.css";

const app = createApp(App);
const pinia = createPinia()
app.use(pinia)

app.use(PrimeVue);
app.use(router);
app.use(LitepieDatepicker);
app.mount('#app');




